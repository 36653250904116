import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { parseDateTime } from 'stash/components/utils/dateTime';

class IdvAttemptTable extends Component {
  constructor(props) {
    super(props);
    this.sortedIdvAttempts = props.idvAttempts
      ? props.idvAttempts.sort(
          (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
        )
      : [];
  }

  noIdvAttemptsTableRows() {
    return (
      <TableRow>
        <TableCell colSpan={5}>No IDV Info found</TableCell>
      </TableRow>
    );
  }

  idvAttemptsTableRows() {
    return this.sortedIdvAttempts.map((attempt) => {
      return (
        <TableRow>
          <TableCell>
            KYC:
            {`${attempt.kycStatus}`}
            <br />
            OFAC:
            {`${attempt.ofacStatus}`}
          </TableCell>
          <TableCell>{`${attempt.status}`}</TableCell>
          <TableCell>{`${attempt.idvRequestType}`}</TableCell>
          <TableCell>{`${attempt.kycPendingGate}`}</TableCell>
          <TableCell>{parseDateTime(attempt.publishedAt)}</TableCell>
        </TableRow>
      );
    });
  }

  render() {
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>KYC/OFAC</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Request Type</TableCell>
              <TableCell>KYC Pending Gate</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.sortedIdvAttempts.length === 0
              ? this.noIdvAttemptsTableRows()
              : this.idvAttemptsTableRows()}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export { IdvAttemptTable };
