import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';

const styleLoader = {
  paddingTop: '35px',
  paddingBottom: '35px',
};

export const Loader = () => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell align={'center'} style={styleLoader}>
          <CircularProgress />
        </TableCell>
      </TableRow>
    </TableHead>
  </Table>
);
