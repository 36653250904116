import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useDataApi } from '../../middleware/fetch';
import { Loader } from 'stash/components/utils/Loader';
import Paper from '@material-ui/core/Paper';

const PageableTable = (props) => {
  const {
    initialPage = 0,
    apiPath,
    parseData,
    headers = [],
    rowsPerPageOptions = [25, 50, 100],
  } = props;

  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRows] = useState(rowsPerPageOptions[0]);

  const { data, isLoading, isError, doFetch } = useDataApi(
    {
      method: 'GET',
      url: `${apiPath}?limit=${rowsPerPage}&offset=${page * rowsPerPage}`,
    },
    {}
  );

  const { total = 0, events = [] } = parseData(data);

  const getMoreRows = (page) => {
    doFetch({
      method: 'GET',
      url: `${apiPath}?limit=${rowsPerPage}&offset=${page * rowsPerPage}`,
    });
  };

  const handleChangePage = (event, page) => {
    setPage(page);
    getMoreRows(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRows(event.target.value);
    getMoreRows(event.target.value);
  };
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return (
      <TableRow>
        <TableCell colSpan={5}>Error fetching events.</TableCell>
      </TableRow>
    );
  }
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((h) => (
              <TableCell key={h}>{h}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <Events events={events} headers={headers} />
        </TableBody>
        <TableFooter>
          <TableRow>
            {events ? (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : null}
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

const Events = ({ events, headers }) => {
  if (!events) {
    return <Loader />;
  }

  if (!events.length) {
    return (
      <TableRow>
        <TableCell colSpan={5}>No events found.</TableCell>
      </TableRow>
    );
  }
  return events.map((e) => (
    <TableRow key={e.key}>
      {headers.map((h) => (
        <TableCell key={h}>
          {typeof e[h] === 'function' ? e[h]() : e[h]}
        </TableCell>
      ))}
    </TableRow>
  ));
};

export default PageableTable;
