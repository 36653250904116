import { connect } from 'react-redux';
import Header from './Header';
import { setNightMode } from 'stash/actions/theme';
import { setMenuOpen } from 'stash/actions/modal';

const mapStateToProps = (state, ownProps) => ({
  menuOpen: state.modal.menuOpen,
  nightMode: state.theme.nightMode,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setNightMode: (enabled) => {
    return dispatch(setNightMode(enabled));
  },
  setMenuOpen: (enabled) => {
    return dispatch(setMenuOpen(enabled));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
