import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import BrightnessIcon from '@material-ui/icons/Brightness4';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';

const pathnameToTitle = (str) => {
  return str
    .split('-')
    .map((i) => {
      if (i === 'ach' || i === 'mrdc') {
        return i.toUpperCase();
      } else {
        return i.charAt(0).toUpperCase() + i.substr(1).toLowerCase();
      }
    })
    .join(' ');
};

const pathnameToHeader = (pathname) => {
  const fragment = pathname.split('/').pop();
  switch (fragment) {
    case 'auto-invest':
      return `Recurring transactions - auto-invest`;
    case 'auto-add-cash':
      return `Recurring transactions - auto-add cash`;
    case 'smart-stash':
      return `Recurring transactions - Smart-Stash`;
    case 'round-ups':
      return `Recurring transactions - Round-ups`;
    case 'debit':
      return `Bank Account`;
    case 'payment-instruments':
      return `Bank - Stock-Back Card`;
    default:
      return `Bank - ${pathnameToTitle(fragment)}`;
  }
};

class Header extends React.Component {
  handleNightModeClick = () => {
    const { setNightMode, nightMode } = this.props;
    setNightMode(!nightMode);
  };

  handleMenuClick = () => {
    const { setMenuOpen, menuOpen } = this.props;
    setMenuOpen(!menuOpen);
  };

  renderTitle = () => {
    return (
      <Typography variant="h5" component="h1">
        {pathnameToHeader(this.props.location.pathname)}
      </Typography>
    );
  };

  renderMenu = () => {
    return (
      <>
        {this.props.menu && (
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
      </>
    );
  };

  renderToolbar = () => {
    const logout = `/auth/okta/logout`;
    return (
      <React.Fragment>
        <Tooltip title="Night Mode" placement="bottom">
          <IconButton
            color="inherit"
            aria-label="Night mode"
            onClick={this.handleNightModeClick}
          >
            <BrightnessIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Refresh Session" placement="bottom" href={logout}>
          <IconButton color="inherit" aria-label="Night mode">
            <ThreeSixtyIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  };

  render() {
    return (
      <AppBar position="static" color="default">
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid>{this.renderMenu()}</Grid>
            <Grid>{this.renderTitle()}</Grid>
            <Grid>{this.renderToolbar()}</Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Header;
