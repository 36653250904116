import React from 'react';

import { BrowserRouter } from 'react-router-dom';

// export const history = createBrowserHistory();
// export const reducerWithRouter = connectRouter(history);

export const Router = (props) => (
  <BrowserRouter>
    <div>{props.children}</div>
  </BrowserRouter>
);
