import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parseDateTime } from 'stash/components/utils/dateTime';
import PageableTable from 'stash/components/utils/PageableTable';
import Paper from '@material-ui/core/Paper';
import { useDataApi } from 'stash/middleware/fetch';

const init = {
  roundUps: {},
  user: {},
};

const getUserRoundUps = (id) => ({
  method: 'GET',
  url: `/api/v1/users/${id}/roundUps`,
});

const StatusRow = ({ name, subtexts, children }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="inherit" gutterBottom>
          {name}
        </Typography>
        {subtexts &&
          subtexts.map((text, idx) => (
            <Typography key={idx} variant="inherit" color="primary">
              <small>{text}</small>
            </Typography>
          ))}
      </TableCell>
      <TableCell align="right">{children}</TableCell>
    </TableRow>
  );
};

const noValue = 'N/A';
const StatusTable = ({ account, id }) => {
  const {
    status = noValue,
    creditCardsEnabled = noValue,
    statusChangedDate = noValue,
    pauseDuration = noValue,
    resumeDate = noValue,
    lifetimeSaved = noValue,
  } = account || {};

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StatusRow name="Status">{String(status)}</StatusRow>
          <StatusRow name="CreditCards">{String(creditCardsEnabled)}</StatusRow>
          <StatusRow name="Status Changed">
            {parseDateTime(statusChangedDate)}
          </StatusRow>
          <StatusRow name="PauseDuration">{String(pauseDuration)}</StatusRow>
          <StatusRow name="Resume Date">
            {resumeDate ? parseDateTime(resumeDate) : String(null)}
          </StatusRow>
          <StatusRow name="Lifetime Saved">{lifetimeSaved}</StatusRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

const RoundUpsOverview = ({ data, userId }) => {
  const { roundUps } = data;
  return (
    <React.Fragment>
      <Grid item xs={12} md={10} lg={8} xl={6}>
        <Typography variant="subtitle1" gutterBottom>
          Round-ups Status
        </Typography>
        <StatusTable id={userId} account={roundUps} />
      </Grid>
      <Grid item xs={10}>
        <Typography variant="subtitle1" gutterBottom>
          Recent Events
        </Typography>
        <PageableTable
          headers={['Event Type', 'Details', 'Date', 'More Info']}
          apiPath={`/api/v1/users/${userId}/roundUps`}
          parseData={(data) => {
            const { roundUps: { transfers = [], total = 0 } = {} } = data || {};
            return {
              total,
              events: transfers.map((e, idx) => ({
                key: idx,
                'Event Type': e.description,
                Details: e.details,
                Date: parseDateTime(e.date),
                'More Info': () => {
                  return e.transferPublicId ? (
                    <a
                      href={`https://${process.env.REACT_APP_ENV}.stsh.io/admin/transfers?&q%5Bpublic_id_eq%5D=${e.transferPublicId}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Active Admin
                    </a>
                  ) : (
                    ''
                  );
                },
              })),
            };
          }}
        />
      </Grid>
    </React.Fragment>
  );
};

const RoundUpsError = (props) => {
  return (
    <Grid item>
      <Typography variant="subtitle1" gutterBottom>
        Error
      </Typography>
      <Typography gutterBottom>
        Couldn&apos;t find Round-ups data for this user
      </Typography>
      <Typography gutterBottom>
        Details: {JSON.stringify(props.error)}
      </Typography>
    </Grid>
  );
};

const RoundUps = (props) => {
  const { data: roundUps } = useDataApi(
    getUserRoundUps(props.match.params.userId),
    init
  );
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      {roundUps.errors ? (
        <RoundUpsError error={roundUps.errors} />
      ) : (
        <RoundUpsOverview data={roundUps} userId={props.match.params.userId} />
      )}
    </Grid>
  );
};

export default RoundUps;
