import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';

export const AddressTable = ({ address }) => {
  const noAddressTableRows = () => {
    return (
      <TableRow>
        <TableCell colSpan={5}>No Address Info found</TableCell>
      </TableRow>
    );
  };

  const addressTableRows = () => {
    return (
      <TableRow>
        <TableCell>
          {address.addressLine1}
          <br />
          {address.addressLine2}
        </TableCell>
        <TableCell>{address.city}</TableCell>
        <TableCell>{address.state}</TableCell>
        <TableCell>{address.postalCode}</TableCell>
      </TableRow>
    );
  };

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Street</TableCell>
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Postal Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {address.length === 0 ? noAddressTableRows() : addressTableRows()}
        </TableBody>
      </Table>
    </Paper>
  );
};
