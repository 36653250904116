import { combineReducers } from 'redux';
// import { reducerWithRouter } from 'stash/router';
import theme from './theme';
import modal from './modal';

const rootReducer = combineReducers({
  theme,
  modal,
});

export default rootReducer;
