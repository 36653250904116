import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import React from 'react';
import { parseDateTime } from 'stash/components/utils/dateTime';
import { Loader } from 'stash/components/utils/Loader';
import Paper from '@material-ui/core/Paper';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const Transactions = ({ transactions, userId }) => {
  if (!transactions) {
    return <Loader />;
  }

  if (!transactions.length) {
    return (
      <TableRow>
        <TableCell colSpan={5}>No transactions found.</TableCell>
      </TableRow>
    );
  }
  return transactions.map((t) => (
    <TableRow key={t.id}>
      <TableCell>${t.amount.value}</TableCell>
      <TableCell>{t.description || 'Unknown merchant'}</TableCell>
      <TableCell>{t.type}</TableCell>
      <TableCell>{parseDateTime(t.date)}</TableCell>
      <TableCell>{t.status}</TableCell>
      <TableCell>
        <Link
          to={`/users/${userId}/transactions/${t.id}`}
          component={RouterLink}
        >
          view
        </Link>
      </TableCell>
    </TableRow>
  ));
};

const TransactionsTable = ({ transactions, userId }) => {
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Amount</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <Transactions transactions={transactions} userId={userId} />
        </TableBody>
      </Table>
    </Paper>
  );
};

export { Transactions, TransactionsTable };
