import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { Loader } from 'stash/components/utils/Loader';
import { activeAdminUrlBuilder } from 'stash/components/utils';
import Money from 'stash/components/utils/Money';
import StatusRow from 'stash/components/utils/StatusRow';
import { extractUUIDs } from 'stash/utils/extractUUIDs';
import { useDataApi } from 'stash/middleware/fetch';
import { setScheduleNextExecution } from 'stash/utils/SetScheduleNextExecution';
import { fetch } from '../../middleware/fetch.js';
import { TurnOffButton } from '../setSchedule/TurnOffButton'
import { Permissioned } from '../permissioned';

const turnOffUserRecurringTransfer = async (userId, recurringTransferId) => {

  const url = `/api/v1/users/${userId}/recurringTransfers/${recurringTransferId}`;
  const resp = await fetch('DELETE', url);
  const respBody = await resp.json();

  if ('errors' in respBody) {
    return alert(
      `Could not turn off user's auto-invest. ${JSON.stringify(respBody.errors)}`
    );
  }

  alert(`This user's auto-invest was successfully turned off`);
  window.location.reload();
};

const renderTurnOffButton = (userId, recurringTransferId) => {
  return (
    <TableRow>
      <TableCell />
      <TableCell align="right">
        <Typography variant="inherit" gutterBottom>
          <br />
          <TurnOffButton
            userId={userId}
            recurringTransferId={recurringTransferId}
            onClick={turnOffUserRecurringTransfer}
          />
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusTable = ({ recurringTransfer, userId: user }) => {
  const {
    interval,
    nextExecutionDate: nextExecution = '',
    amount,
    source,
    status,
    pausedUntil,
    enabled,
    destinationUri,
    allocations,
  } = recurringTransfer;

  const accountId = extractUUIDs(destinationUri).uuidArr[0];
  const activeAdminLink = activeAdminUrlBuilder({
    path: `admin/accounts/${accountId}/auto_stash_executions`,
  });

  return (
    <Paper>
      <Table>
        <TableBody>
          <StatusRow name="Funding Source">{source}</StatusRow>
          <StatusRow name="Amount">
            <Money balance={amount.value} />
          </StatusRow>
          <StatusRow name="Next Execution" subtexts={[interval]}>
            {setScheduleNextExecution(enabled, pausedUntil, nextExecution)}
          </StatusRow>
          <StatusRow name="Status">{status}</StatusRow>
          <StatusRow name="Executions">
            {' '}
            <a href={activeAdminLink} rel="noopener noreferrer" target="_blank">
              View in Active Admin
            </a>
          </StatusRow>
          <Permissioned permission="stash::bank::recurring_transfers::delete">
            {status != 'Off' ? (
              renderTurnOffButton(user, recurringTransfer.id)
            ) : (
              <span />
            )}
          </Permissioned>
        </TableBody>
      </Table>
    </Paper>
  );
};

const SetScheduleDetail = ({ recurringTransfer = {}, userId }) => {
  if (!recurringTransfer.id) return null;
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={10} sm={10} lg={6} xl={6}>
          <Typography variant="subtitle1" gutterBottom>
            Current Settings
          </Typography>
          <StatusTable userId={userId} recurringTransfer={recurringTransfer} />
        </Grid>
        <Grid item xs={10} sm={10} lg={6} xl={6}>
          <Typography variant="subtitle1" gutterBottom>
            Allocations
          </Typography>
          <AllocationsTable
            userId={userId}
            allocations={recurringTransfer.allocations}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const SetSchedule = (props) => {
  const { userId, setScheduleId } = props.match.params;
  const { data, isLoading, isError, doFetch } = useDataApi(
    {
      method: 'GET',
      url: `/api/v1/users/${userId}/autoStash/setSchedule/${setScheduleId}`,
    },
    {
      recurringTransfer: {},
    }
  );

  if (isLoading) return <Loader />;
  if (isError) return <div>Error loading user's auto-invest settings.</div>;
  return (
    <SetScheduleDetail
      recurringTransfer={data.recurringTransfer}
      userId={userId}
    />
  );
};

const AllocationsTable = ({ allocations = [], userId: user }) => {
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Ticker</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <Allocations allocations={allocations} />
        </TableBody>
      </Table>
    </Paper>
  );
};

const Allocations = ({ allocations, userId: user }) => {
  if (!allocations.length) {
    return (
      <TableRow>
        <TableCell colSpan={5}>No allocations found.</TableCell>
      </TableRow>
    );
  }
  return allocations.map((allocation) => (
    <TableRow key={allocation.securityUUID}>
      <TableCell>{allocation.securityName}</TableCell>
      <TableCell>
        <Money balance={allocation.securityAmount} />
      </TableCell>
    </TableRow>
  ));
};

export default SetSchedule;
