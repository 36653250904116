import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Paper,
  TableBody,
  Table,
  TableRow,
  TableCell,
  TableHead,
} from '@material-ui/core';

import Money from '../utils/Money';

const PartitionsRow = ({ title, balance }) => {
  return (
    <TableRow key={title}>
      <TableCell>
        <Typography variant="inherit" gutterBottom>
          {title}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Money balance={balance} />
      </TableCell>
    </TableRow>
  );
};

const PartitionsList = ({ partitions }) => {
  return (
    <Table>
      <TableHead>
        <TableRow key="headers">
          <TableCell>Partition Name</TableCell>
          <TableCell align="right">Balance</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {partitions.map((partition) => (
          <PartitionsRow title={partition.name} balance={partition.balance} />
        ))}
      </TableBody>
    </Table>
  );
};

const NoPartitions = () => {
  return (
    <Typography variant="subtitle1" align="center" gutterBottom>
      User has no Partitions
    </Typography>
  );
};

const PartitionsListWrapper = ({ partitions }) => {
  return (
    <Paper>
      {partitions === undefined || partitions.length === 0 ? (
        <NoPartitions />
      ) : (
        <PartitionsList partitions={partitions} />
      )}
    </Paper>
  );
};

export default PartitionsListWrapper;
