import React from 'react';
import { Provider } from 'react-redux';
import {
  createFeatureFlagProvider,
  FeatureFlagIdentifier,
  EnvironmentProvider,
  Environments,
} from '@stashinvest/internal-tooling-ui';

import { useOktaProfile } from 'stash/hooks';
import Dashboard from 'stash/components/dashboard';
import Theme from 'stash/components/theme';
import store from 'stash/store';
import { Router } from 'stash/router';
import { TrackingProvider } from 'stash/components/trackingProvider';
import { ReferralProvider } from 'stash/components/referralProvider';
import { PermissionsProvider } from 'stash/components/permissionsProvider';
import { PermissionsSetter } from 'stash/components/permissionsSetter';
import { flagsToRequest } from 'stash/utils/flags';

const FeatureFlagProvider = createFeatureFlagProvider(
  process.env.REACT_APP_LD_CLIENT,
  flagsToRequest
);

const getEnv = () => {
  const env = process.env.REACT_APP_ENV;
  if (env === 'prod') {
    return Environments.PROD;
  }

  if (env === 'staging') {
    return Environments.STAGING;
  }

  return Environments.EDGE;
};

const App = (props) => {
  const { oktaProfile } = useOktaProfile();

  return (
    <FeatureFlagProvider>
      <FeatureFlagIdentifier oktaProfile={oktaProfile}>
        <Provider store={store}>
          <Theme>
            <Router>
              <PermissionsProvider>
                <PermissionsSetter>
                  <ReferralProvider>
                    <TrackingProvider oktaProfile={oktaProfile}>
                      <EnvironmentProvider environmentToSet={getEnv()}>
                        <Dashboard>{props.children}</Dashboard>
                      </EnvironmentProvider>
                    </TrackingProvider>
                  </ReferralProvider>
                </PermissionsSetter>
              </PermissionsProvider>
            </Router>
          </Theme>
        </Provider>
      </FeatureFlagIdentifier>
    </FeatureFlagProvider>
  );
};

export default App;
