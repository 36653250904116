import React from 'react';
import { Paper, TableBody, Table } from '@material-ui/core';

import Money from '../utils/Money';
import { StatusRow } from '../checking/StatusRow';

const BalanceSummary = ({ account }) => {
  const balanceSummary = account.balanceSummary;
  return (
    <Paper>
      <Table>
        <TableBody>
          <StatusRow name="Partitions Balance">
            <Money balance={balanceSummary.partitionsBalance} />
          </StatusRow>
          <StatusRow name="Available Balance">
            <Money balance={account.availableBalance.amount} />
          </StatusRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default BalanceSummary;
