import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Checking from 'stash/components/checking';
import RecurringTransfers from 'stash/components/recurringTransfers';
import SmartStash from 'stash/components/smartStash';
import RoundUps from 'stash/components/roundUps';
import TransactionDetails from 'stash/components/transactionDetails/TransactionDetails';
import TransactionShow from 'stash/components/transactionShow/transactionShow';
import AchPull from 'stash/components/achPull/AchPull';
import BillPay from 'stash/components/billPay/BillPay';
import MRDC from 'stash/components/mrdc/mrdc';
import DirectDepositStatus from 'stash/components/directDeposit/DirectDeposit';
import CardInfo from 'stash/components/cardInfo';
import Partitions from 'stash/components/partitions';
import SetScheduleOverview from 'stash/components/setScheduleOverview/SetScheduleOverview';
import SetSchedule from 'stash/components/setSchedule/SetSchedule';

const styles = (theme) => ({
  main: {
    padding: theme.spacing(1.1),
  },
});

const Main = (props) => {
  return (
    <Grid className={props.classes.main}>
      <Switch>
        <Route exact path="/users/:userId/debit" component={Checking} />
        <Route
          exact
          path="/users/:userId/transaction-details"
          component={TransactionDetails}
        />
        <Route
          exact
          path="/users/:userId/transactions/:eventId"
          component={TransactionShow}
        />
        <Route
          exact
          path="/users/:userId/transactionsByTransactionId/:transactionId"
          render={(props) => (
            <TransactionShow {...props} fetchByTransactionId />
          )}
        />
        <Route exact path="/users/:userId/ach-pull" component={AchPull} />
        <Route
          exact
          path="/users/:userId/auto-add-cash"
          component={RecurringTransfers}
        />
        <Route exact path="/users/:userId/smart-stash" component={SmartStash} />
        <Route exact path="/users/:userId/round-ups" component={RoundUps} />
        <Route exact path="/users/:userId/mrdc" component={MRDC} />
        <Route
          exact
          path="/users/:userId/direct-deposit"
          component={DirectDepositStatus}
        />
        <Route exact path="/users/:userId/partitions" component={Partitions} />
        <Route
          exact
          path="/users/:userId/payment-instruments"
          component={CardInfo}
        />
        <Route exact path="/users/:userId/bill-pay" component={BillPay} />
        <Route
          exact
          path="/users/:userId/auto-invest"
          component={SetScheduleOverview}
        />
        <Redirect
          exact
          path="/users/:userId/set-schedule"
          to="/users/:userId/auto-invest"
        />
        <Route
          exact
          path="/users/:userId/auto-invest/:setScheduleId"
          component={SetSchedule}
        />
        {props.children}
      </Switch>
    </Grid>
  );
};

export default withStyles(styles)(Main);
