import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useDataApi } from '../../middleware/fetch';
import { parseDateTime } from 'stash/components/utils/dateTime';
import { Loader } from 'stash/components/utils/Loader';
import PageableTable from 'stash/components/utils/PageableTable';
import Paper from '@material-ui/core/Paper';

const init = {
  billPay: {
    prerequisitesInfo: {
      cardActivated: false,
      phoneVerified: false,
      phoneVerifiedDate: '',
    },
  },
};

const PrerequisitesRow = ({ name, subtexts, children }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography gutterBottom>{name}</Typography>
        {subtexts &&
          subtexts.map((text, idx) => (
            <Typography key={idx} color="primary">
              <small>{text}</small>
            </Typography>
          ))}
      </TableCell>
      <TableCell align="right">{children}</TableCell>
    </TableRow>
  );
};

const PrerequisitesTable = ({ prerequisites }) => {
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <PrerequisitesRow name="Card Activated">
            <Chip label={prerequisites?.cardActivated ? 'True' : 'False'} />
          </PrerequisitesRow>
          <PrerequisitesRow
            name="Phone Verified"
            subtexts={
              prerequisites &&
              prerequisites.phoneVerifiedDate && [
                `Requested at ${parseDateTime(
                  prerequisites.phoneVerifiedDate
                )}`,
              ]
            }
          >
            <Chip label={prerequisites?.phoneVerified ? 'True' : 'False'} />
          </PrerequisitesRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

const BillPayOverview = ({ billPay, isLoading, isError, userId }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Typography variant="subtitle1" gutterBottom>
            Prerequisites
          </Typography>
          {isLoading && <Loader />}
          {isError && <h1>We're sorry an error occurred</h1>}
          {billPay && (
            <PrerequisitesTable prerequisites={billPay.prerequisitesInfo} />
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Typography variant="subtitle1" gutterBottom>
            Payees
          </Typography>
          <PageableTable
            headers={['Recipient', 'Payee Identifier']}
            apiPath={`/api/v1/users/${userId}/debit/billPay/payees`}
            parseData={(data) => {
              const { payees: events = [], total = 0 } = data || {};
              return {
                total,
                events: events.map((event, idx) => ({
                  key: idx,
                  Recipient: event.name,
                  'Payee Identifier': event.id,
                })),
              };
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <br />
          <br />
          <Typography variant="subtitle1" gutterBottom>
            Upcoming Payments
          </Typography>
          <PageableTable
            headers={[
              'Recipient Name',
              'Type',
              'Withdrawal Date',
              'Expected Date',
              'Amount',
              'Confirmation',
              'Status',
            ]}
            apiPath={`/api/v1/users/${userId}/debit/billPay/upcomingPayments`}
            parseData={(data) => {
              const { payments: events = [], total = 0 } = data || {};
              return {
                total,
                events: events.map((event, idx) => ({
                  key: idx,
                  'Recipient Name': event.payeeName,
                  Type: event.payeeType,
                  'Withdrawal Date': parseDateTime(event.paymentDate),
                  'Expected Date': parseDateTime(event.deliveryDate),
                  Amount: event.amount,
                  Confirmation: event.confirmation,
                  Status: event.status,
                })),
              };
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <br />
          <br />
          <Typography variant="subtitle1" gutterBottom>
            Payment History
          </Typography>

          <PageableTable
            headers={[
              'Recipient Name',
              'Type',
              'Withdrawal Date',
              'Expected Date',
              'Amount',
              'Confirmation',
              'Status',
            ]}
            apiPath={`/api/v1/users/${userId}/debit/billPay/previousPayments`}
            parseData={(data) => {
              const { payments: events = [], total = 0 } = data || {};
              return {
                total,
                events: events.map((event, idx) => ({
                  key: idx,
                  'Recipient Name': event.payeeName,
                  Type: event.payeeType,
                  'Withdrawal Date': parseDateTime(event.paymentDate),
                  'Expected Date': parseDateTime(event.deliveryDate),
                  Amount: event.amount,
                  Confirmation: event.confirmation,
                  Status: event.status,
                })),
              };
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const BillPay = (props) => {
  const { data, isLoading, isError } = useDataApi(
    {
      method: 'GET',
      url: `/api/v1/users/${props.match.params.userId}/debit/billPay`,
    },
    init.billPay
  );
  return (
    <BillPayOverview
      billPay={data}
      isLoading={isLoading}
      isError={isError}
      userId={props.match.params.userId}
    />
  );
};

export default BillPay;
