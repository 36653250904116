import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parseDateTime } from 'stash/components/utils/dateTime';
import PageableTable from 'stash/components/utils/PageableTable';
import Paper from '@material-ui/core/Paper';
import { useDataApi } from 'stash/middleware/fetch';

const init = {
  smartStash: {},
  user: {},
};

const getUserSmartStash = (id) => ({
  method: 'GET',
  url: `/api/v1/users/${id}/smartStash`,
});

const StatusRow = ({ name, subtexts, children }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="inherit" gutterBottom>
          {name}
        </Typography>
        {subtexts &&
          subtexts.map((text, idx) => (
            <Typography key={idx} variant="inherit" color="primary">
              <small>{text}</small>
            </Typography>
          ))}
      </TableCell>
      <TableCell align="right">{children}</TableCell>
    </TableRow>
  );
};

const noValue = 'N/A';
const StatusTable = ({ account, id }) => {
  const {
    status = noValue,
    statusChangedDate = noValue,
    pauseDuration = noValue,
    resumeDate = noValue,
    lifetimeSaved = noValue,
  } = account || {};

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StatusRow name="Status">{String(status)}</StatusRow>
          <StatusRow name="Status Changed">
            {parseDateTime(statusChangedDate)}
          </StatusRow>
          <StatusRow name="PauseDuration">{String(pauseDuration)}</StatusRow>
          <StatusRow name="Resume Date">
            {resumeDate ? parseDateTime(resumeDate) : String(null)}
          </StatusRow>
          <StatusRow name="Lifetime Saved">{lifetimeSaved}</StatusRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

const SmartStashOverview = ({ data, userId }) => {
  const { smartStash } = data;
  return (
    <React.Fragment>
      <Grid item>
        <Typography variant="subtitle1" gutterBottom>
          Smart-Stash Status
        </Typography>
        <Grid item xs={12} md={10} lg={8} xl={6}>
          <StatusTable id={userId} account={smartStash} />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" gutterBottom>
          Recent Events
        </Typography>
        <Grid item xs={10}>
          <PageableTable
            headers={['Event Type', 'Details', 'Date', 'More Info']}
            apiPath={`/api/v1/users/${userId}/smartStash`}
            parseData={(data) => {
              const { smartStash: { transfers = [], total = 0 } = {} } =
                data || {};
              return {
                total,
                events: transfers.map((e, idx) => ({
                  key: idx,
                  'Event Type': e.description,
                  Details: e.details,
                  Date: parseDateTime(e.date),
                  'More Info': () => {
                    return e.transferPublicId ? (
                      <a
                        href={`https://${process.env.REACT_APP_ENV}.stsh.io/admin/transfers?&q%5Bpublic_id_eq%5D=${e.transferPublicId}`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Active Admin
                      </a>
                    ) : (
                      ''
                    );
                  },
                })),
              };
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const SmartStashError = (props) => {
  return (
    <div>
      <Grid item>
        <Typography variant="subtitle1" gutterBottom>
          Error
        </Typography>
        <Typography gutterBottom>
          Couldn&apos;t find smart-stash data for this user.
        </Typography>
        <Typography gutterBottom>
          Details: {JSON.stringify(props.error)}
        </Typography>
      </Grid>
    </div>
  );
};

const SmartStash = (props) => {
  const { data: smartStash } = useDataApi(
    getUserSmartStash(props.match.params.userId),
    init
  );
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      {smartStash.errors ? (
        <SmartStashError error={smartStash.errors} />
      ) : (
        <React.Fragment>
          <SmartStashOverview
            data={smartStash}
            userId={props.match.params.userId}
          />
        </React.Fragment>
      )}
    </Grid>
  );
};

export default SmartStash;
