import React from 'react';
import { useEffect } from 'react';
import { useFetch } from '@stashinvest/use-fetch';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import { Loader } from 'stash/components/utils/Loader';

import { parseDateTime } from 'stash/components/utils/dateTime';
import Chip from '@material-ui/core/Chip';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

export function CardInfo(props) {
  const userId = props.match.params.userId;

  // TODO: investigate moving this accounts call higher up to avoid re-calling on other pages
  const { data: accountData } = useFetch({
    path: `/api/v1/users/${userId}/debit/account`,
    onMount: true,
  });

  const accountId = accountData && accountData.account.id;
  const { data: paymentInstrumentsData, fetch: fetchPaymentInstruments } =
    useFetch({
      path: `/api/v1/users/${userId}/accounts/${accountId}/paymentInstruments`,
    });

  const { data: virtualCardWhitelisted, fetch: fetchWhitelistedStatus } =
    useFetch({
      path: `/api/v1/users/${userId}/accounts/${accountId}/virtualCardWhitelisted`,
    });

  const { data: cardTrackingEventsData, fetch: fetchCardTrackingEvents } =
    useFetch({
      path: `/api/v1/users/${userId}/accounts/${accountId}/cardTracking`,
    });

  useEffect(() => {
    if (accountId) {
      fetchPaymentInstruments();
      fetchCardTrackingEvents();
      fetchWhitelistedStatus();
    }
  }, [accountId]);

  return virtualCardWhitelisted ||
    paymentInstrumentsData ||
    cardTrackingEventsData ? (
    <>
      {paymentInstrumentsData ? (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Cards
            <Chip
              label={
                virtualCardWhitelisted.whitelisted
                  ? 'Virtual Card Whitelisted'
                  : 'Not in Virtual Card Whitelist'
              }
              style={{
                color: virtualCardWhitelisted.whitelisted
                  ? green[500]
                  : red[500],
                marginLeft: 50,
              }}
            />
          </Typography>
          <Paper style={{ marginBottom: '1rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Card Number (Last Four)</TableCell>
                  <TableCell>Status - Details</TableCell>
                  <TableCell>Pin Set</TableCell>
                  <TableCell>Issued at</TableCell>
                  <TableCell>Activated at</TableCell>
                  <TableCell>Card Locked Date Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentInstrumentsData.paymentInstruments.map((pi) => (
                  <TableRow key={pi.id}>
                    <TableCell>{pi.type}</TableCell>
                    <TableCell>{pi.last4Pan}</TableCell>
                    <TableCell>
                      {pi.status} -{' '}
                      {pi.statusReasons
                        ? pi.statusReasons
                            .join(', ')
                            .replace('accountBlock', 'Green Dot Lock')
                            .replace(
                              'customerInitiatedHold',
                              'Customer Initiated Lock'
                            )
                            .replace('upgraded', 'Old Card')
                        : 'N/A'}
                    </TableCell>
                    <TableCell>{String(pi.isPinSet)}</TableCell>
                    <TableCell>{parseDateTime(pi.issuedDateTime)}</TableCell>
                    <TableCell>
                      {pi.activatedDateTime
                        ? parseDateTime(pi.activatedDateTime)
                        : 'Never Activated'}
                    </TableCell>
                    <TableCell>
                      {pi.cardPausedDateTime
                        ? parseDateTime(pi.cardPausedDateTime)
                        : 'Card Unlocked'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </>
      ) : (
        <Loader />
      )}

      {cardTrackingEventsData ? (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Card Tracking Events
          </Typography>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Card Number (Last Four)</TableCell>
                  <TableCell>Material</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Mailing Method</TableCell>
                  <TableCell>Shipped</TableCell>
                  <TableCell>Estimated Arrival</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cardTrackingEventsData.cardTracking.map((event) => {
                  return (
                    <TableRow key={event.publishedAt}>
                      <TableCell>
                        {parseDateTime(
                          event.publishedAt.replace(' ', 'T') + 'Z'
                        )}
                      </TableCell>
                      <TableCell>{event.last4Pan}</TableCell>
                      <TableCell>{event.materialType}</TableCell>
                      <TableCell>{event.status}</TableCell>
                      <TableCell>{event.mailingMethod}</TableCell>
                      <TableCell>{parseDateTime(event.shippedDate)}</TableCell>
                      <TableCell>
                        {parseDateTime(event.estimatedArrivalDate)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </>
      ) : (
        <Loader />
      )}
    </>
  ) : (
    <Loader />
  );
}

export default CardInfo;
