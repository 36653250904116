import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useTracking, GA_HOST } from 'stash/hooks';
import { extractUUIDs } from '../../utils/extractUUIDs';
import { useReferral } from 'stash/hooks/useReferral';

const PROD_ENV = 'prod';
const GA_TAG = 'UA-62761031-10';
const FALLBACK_TAG = 'UA-XXXXXXXX-XX';

const TrackingScript = memo(({ tag, groups, zendeskTicket, zendeskUser }) => {
  const redactedPath = extractUUIDs(window.location.pathname);
  const path = redactedPath.amendedString;
  const page = `${GA_HOST}${path}`;
  const uuids = redactedPath.uuids || '';

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${tag}`}
      ></script>
      <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag("js", new Date());
        gtag("config", "${tag}", {
          "page_title": "Checking Portal",
          "page_location": "${page}",
          "page_path": "${page}",

          // dimension1 = "groups"
          "dimension1": "${groups}",

          // dimension2 = "uuids"
          "dimension2": "${uuids}",

          // dimension5 = "zendeskTicket"
          "dimension5": "${zendeskTicket}",

          // dimension6 = "zendeskUser"
          "dimension6": "${zendeskUser}",
        });
        `}
      </script>
    </Helmet>
  );
});

export const TrackingProvider = ({ children, oktaProfile }) => {
  const { zendeskTicket, zendeskUser } = useReferral();
  const hasGroups =
    oktaProfile && oktaProfile.groups && oktaProfile.groups.length > 0;
  const groups = hasGroups ? oktaProfile.groups.toString() : undefined;
  const env = process.env.REACT_APP_ENV;
  const tag = env === PROD_ENV ? GA_TAG : FALLBACK_TAG;
  useTracking();

  return (
    <>
      {groups && zendeskTicket !== null && zendeskUser !== null ? (
        <TrackingScript
          tag={tag}
          groups={groups}
          zendeskTicket={zendeskTicket}
          zendeskUser={zendeskUser}
        />
      ) : null}
      {children}
    </>
  );
};
