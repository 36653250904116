import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useDataApi } from '../../middleware/fetch';
import { ymdString } from 'stash/components/utils/dateTime';
import { Loader } from 'stash/components/utils/Loader';
import { NotFound } from 'stash/components/utils/NotFound';
import Paper from '@material-ui/core/Paper';
import { FilterDrawer } from './FilterDrawer';
import get from 'lodash.get';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import {
  statusOpts,
  typeOpts,
  defaultStartDate,
  defaultEndDate,
  defaultSortedDate,
  defaultPopoverOpen,
} from './defaults';
import TypePopover from './TypePopover';
import Link from '@material-ui/core/Link';
import { TypeDefinitions, StatusDefinitions } from './typeDefinitions';
import { Link as RouterLink } from 'react-router-dom';
import { parseDateTime } from 'stash/components/utils/dateTime';
import { DateTime } from 'luxon';

const TransactionDetails = (props) => {
  const tz = DateTime.local().zoneName;
  const transactionDetailsPage = props.location.pathname.includes(
    'transaction-details'
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRows] = useState(transactionDetailsPage ? 25 : 5);
  const [rows] = useState({});
  const [types, setTypes] = useState(typeOpts);
  const [statuses, setStatuses] = useState(statusOpts);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [sortedDate, setSortedDate] = useState(defaultSortedDate);
  const [popoverOpen, setPopoverOpen] = useState(defaultPopoverOpen);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');

  const filterParamsString = () => {
    let params = new URLSearchParams();

    if (Object.values(types).some(Boolean)) {
      params.set(
        'types',
        Object.keys(types).filter((k) => types[k])
      );
    }

    if (Object.values(statuses).some(Boolean)) {
      params.set(
        'statuses',
        Object.keys(statuses).filter((k) => statuses[k])
      );
    }

    if (startDate <= endDate) {
      params.set('start_date', ymdString(startDate));
      params.set('end_date', ymdString(endDate));
    }

    params.set('sort', sortedDate);

    return params.toString();
  };

  const { data, isLoading, isError, doFetch } = useDataApi(
    {
      method: 'GET',
      url: `/api/v1/users/${
        props.match.params.userId
      }/debit/transactions?limit=${rowsPerPage}&offset=${
        page * rowsPerPage
      }&${filterParamsString()}&tz=${encodeURIComponent(tz)}`,
    },
    { rows }
  );

  const getMoreTransactions = (page) => {
    doFetch({
      method: 'GET',
      url: `/api/v1/users/${
        props.match.params.userId
      }/debit/transactions?limit=${rowsPerPage}&offset=${
        page * rowsPerPage
      }&${filterParamsString()}&tz=${encodeURIComponent(tz)}`,
    });
  };

  const totalCount = parseFloat(data.total);

  const handleChangePage = (event, page) => {
    setPage(page);
    handleChangeFilter();
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRows(event.target.value);
    handleChangeFilter();
  };

  const handleChangeSort = () => {
    const newSortedDate = sortedDate === 'desc' ? 'asc' : 'desc';
    setSortedDate(newSortedDate);
    handleChangeFilter();
  };

  const handleTypePopover = (event) => {
    event.stopPropagation();
    const type = event.currentTarget.innerHTML;
    const content =
      type in TypeDefinitions ? TypeDefinitions[type] : 'No definition found';
    setPopoverOpen(true);
    setPopoverAnchor(event.currentTarget);
    setPopoverContent(content);
  };

  const handleTypeStatusPopover = (type) => (event) => {
    event.stopPropagation();
    const status = event.currentTarget.innerHTML;
    const content =
      type in StatusDefinitions && status in StatusDefinitions[type]
        ? StatusDefinitions[type][status]
        : 'No Definition found';
    setPopoverOpen(true);
    setPopoverAnchor(event.currentTarget);
    setPopoverContent(content);
  };

  const handleChangeFilter = () => getMoreTransactions(page);

  const displayInline = {
    display: 'inline',
    fontSize: '0.7375rem',
  };

  const achInSpecialFormatting = (row) => {
    let ach = 'achIn';
    let holdTime = '';
    let typeDescription = '';
    if (row.typeDescription && row.typeDescription === 'Debit') {
      typeDescription = ' TRANSFER OUT';
      ach = 'ach';
    } else if (row.typeDescription && row.typeDescription === 'Deposit') {
      typeDescription = ' TRANSFER IN';
      ach = 'ach';
    } else if (row.typeDescription) {
      typeDescription = row.typeDescription;
    }

    if (row.holdTime) {
      holdTime = `(HoldTime - ${row.holdTime})`;
    }
    return (
      <div>
        <Link color="primary" onClick={handleTypePopover}>
          {ach}
        </Link>
        <Typography variant="subtitle2" style={displayInline}>
          {` ${typeDescription} ${holdTime}`}
        </Typography>
      </div>
    );
  };

  const typeDescriptionFormatted = (row) => {
    if (row.type === 'achIn') {
      return achInSpecialFormatting(row);
    } else {
      let holdTime = '';
      let typeDescription = '';

      if (row.typeDescription) {
        typeDescription = row.typeDescription;
      }

      if (row.holdTime) {
        holdTime = `(HoldTime - ${row.holdTime})`;
      }
      return (
        <div>
          <Link color="primary" onClick={handleTypePopover} gutterBottom>
            {row.type}
          </Link>
          <Typography variant="subtitle2" style={displayInline}>
            {` ${typeDescription} ${holdTime}`}
          </Typography>
        </div>
      );
    }
  };

  const StatusDescriptionFormatted = ({ row }) => {
    return (
      <div>
        <Link color="primary" onClick={handleTypeStatusPopover(row.type)}>
          {row.status}
        </Link>
        {row.statusDescription ? (
          <Typography variant="subtitle2" style={displayInline}>
            {` ${row.statusDescription}`}
          </Typography>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const DisputeFormatted = ({ dispute }) => {
    if (dispute) {
      return (
        <div>
          {dispute.disputeInfo.disputeType}
          <Typography variant="subtitle2" style={{ fontSize: '0.7375rem' }}>
            {` ${parseDateTime(dispute.postedAt)}`}
          </Typography>
        </div>
      );
    } else return null;
  };

  return (
    <React.Fragment>
      <TypePopover
        open={popoverOpen}
        anchorEl={popoverAnchor}
        setPopoverOpen={setPopoverOpen}
        content={popoverContent}
      />
      {transactionDetailsPage && (
        <FilterDrawer
          statusOpts={statusOpts}
          typeOpts={typeOpts}
          setStatuses={setStatuses}
          setTypes={setTypes}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          handleChangeFilter={handleChangeFilter}
        />
      )}
      <Paper>
        <Table size="small">
          <TableHead>
            {isLoading && <Loader />}
            {isError && <h1>We're sorry an error occurred</h1>}
            {!get(data, 'transactions.length') && (
              <NotFound type={'transactions'} />
            )}
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type - Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Disputed</TableCell>
              {transactionDetailsPage ? (
                <>
                  <TableCell>
                    <Tooltip
                      title="Sort"
                      enterDelay={1}
                      onClick={handleChangeSort}
                    >
                      <TableSortLabel
                        active={true}
                        direction={sortedDate.toString()}
                      >
                        Date
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell />
                </>
              ) : (
                <>
                  <TableCell>Date</TableCell> <TableCell />
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.transactions &&
              data.transactions.map((row, idx) => {
                return (
                  <TableRow key={idx} hover>
                    <TableCell>${row.amount.value}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{typeDescriptionFormatted(row)}</TableCell>
                    <TableCell>
                      <StatusDescriptionFormatted row={row} />
                    </TableCell>
                    <TableCell>
                      <DisputeFormatted dispute={row.dispute} />
                    </TableCell>
                    <TableCell>{parseDateTime(row.date)}</TableCell>
                    <TableCell>
                      <Link
                        to={`/users/${props.match.params.userId}/transactions/${row.id}`}
                        component={RouterLink}
                      >
                        view
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          {transactionDetailsPage && (
            <TableFooter>
              <TableRow>
                {data.transactions ? (
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    colSpan={12}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{ native: true }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                ) : null}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default TransactionDetails;
