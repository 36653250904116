import React, { useEffect } from 'react';
import {
  AlertBanner,
  FeatureFlagWrapper,
  Navigation,
} from '@stashinvest/internal-tooling-ui';
import { Box } from '@stashinvest/ui';
import { useFetch } from '@stashinvest/use-fetch';

import Header from 'stash/components/header';
import Main from 'stash/components/main';
import Sidebar from 'stash/components/sidebar';
import { flags } from 'stash/utils/flags';
import { extractUUIDs } from 'stash/utils/extractUUIDs';
import { isOnAutoStashPath as getIsOnAutoStashPath } from 'stash/router/pageGroups';
import Grid from '@material-ui/core/Grid';
import { getBackground } from 'stash/components/theme';

const Dashboard = (props) => {
  const { nightMode } = props;
  const userId = extractUUIDs(window.location.pathname).uuidArr?.[0];
  const isOnAutoStashPath = getIsOnAutoStashPath(userId);
  const { fetch: fetchUserInfo, data: userInfo } = useFetch({
    path: `/api/v1/users/${userId}/userInfo`,
  });

  useEffect(() => {
    if (userId) {
      fetchUserInfo();
    }
  }, [fetchUserInfo, userId]);

  const fullName =
    userInfo?.firstName && userInfo?.lastName
      ? `${userInfo.firstName} ${userInfo.lastName}`
      : '';

  return (
    <Grid>
      <Navigation
        name={fullName}
        userId={userId}
        containerStyles={{
          px: '25px',
          backgroundColor: getBackground(nightMode),
        }}
        showSearchBox
      />
      <Grid
        container
        style={{ minHeight: '100vh' }}
        alignContent="space-between"
        direction="row"
      >
        <Grid item={true} md={3} lg={2} xl={2}>
          <Sidebar />
        </Grid>

        <Grid item={true} xs={12} md={9} lg={10} xl={10}>
          <Header
            item
            menu
            location={props.location}
            style={{ minWidth: '100vw' }}
          />
          {!isOnAutoStashPath ? (
            <FeatureFlagWrapper flag={flags.SHOW_LEGACY_BANK_PROVIDER}>
              <Box p="20px">
                <AlertBanner title="Legacy Bank Account" type="WARNING" />
              </Box>
            </FeatureFlagWrapper>
          ) : null}
          <Main item>{props.children}</Main>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
