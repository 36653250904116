import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { CheckingOverview } from './CheckingOverview';
import { CheckingError } from './CheckingError';
import Typography from '@material-ui/core/Typography';
import { Loader } from 'stash/components/utils/Loader';
import { useParams } from 'react-router-dom';
import { useFetch } from '@stashinvest/use-fetch';

const Checking = (props) => {
  const params = useParams();
  const { userId } = params;

  const { fetch: debitFetch, data: debit, error } = useFetch({
    method: 'GET',
    path: `/api/v1/users/${userId}/debit/account`,
  });

  const { fetch: adminGroupsFetch, data: adminGroups } = useFetch({
    method: 'GET',
    path: '/auth/okta/groups',
    onMount: true,
  });

  useEffect(() => {
    debitFetch();
    adminGroupsFetch();
  }, [debitFetch, adminGroupsFetch]);

  if (error) {
    return <CheckingError error={error} />;
  }

  if (debit && adminGroups) {
    return (
      <React.Fragment>
        <CheckingOverview
          debit={debit}
          id={userId}
          adminGroups={adminGroups}
          debitFetch={debitFetch}
          {...props}
        />
        <br />
        <Typography variant="subtitle1">
          <Link
            to={`/users/${userId}/transaction-details`}
            component={RouterLink}
          >
            All Transactions
          </Link>
        </Typography>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Loader />
    </React.Fragment>
  );
};

export default Checking;
