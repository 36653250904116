import { DateTime } from 'luxon';

export function parseDateTime(jsDate) {
  return DateTime.fromISO(jsDate).toLocaleString(DateTime.DATETIME_FULL, {
    zone: 'utc',
  });
}

export function ymdString(date) {
  return `${date.year}-${date.month}-${date.day}`;
}
