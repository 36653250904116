import * as React from 'react';
import {
  ThemeModeProvider,
  MODE_DARK,
  MODE_LIGHT,
} from '@stashinvest/internal-tooling-ui';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as styles from './styles';

const getTheme = (nightMode) => {
  return nightMode ? styles.dark : styles.light;
};

const getStashTheme = (nightMode) => {
  return nightMode ? MODE_DARK : MODE_LIGHT;
};

export const getBackground = (nightMode) => {
  return {
    backgroundColor: nightMode ? '#212121' : '#fafafa',
  };
};

const Theme = (props) => {
  const { children, nightMode } = props;
  return (
    <div style={getBackground(nightMode)}>
      <CssBaseline />
      <ThemeModeProvider themeOverride={getStashTheme(nightMode)}>
        <MuiThemeProvider theme={getTheme(nightMode)}>
          {children}
        </MuiThemeProvider>
      </ThemeModeProvider>
    </div>
  );
};

export default Theme;
