import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { parseDateTime } from 'stash/components/utils/dateTime';
import PageableTable from 'stash/components/utils/PageableTable';
import Paper from '@material-ui/core/Paper';
import { useDataApi } from 'stash/middleware/fetch';
import { PauseButton } from '../recurringTransfers/PauseButton';
import { fetch } from '../../middleware/fetch.js';
import { adminInCorrectGroup } from 'stash/utils/AdminUtils';

const init = {
  recurringTransfer: {},
  user: {},
};

const getAdminGroups = () => ({
  method: 'GET',
  url: '/auth/okta/groups',
});

const getUserRecurringTransfers = (id) => ({
  method: 'GET',
  url: `/api/v1/users/${id}/debit/recurringTransfers`,
});

const pauseAdminGroups = ['CX Agent Tier 2', 'CX Agent Tier 3'];

const StatusRow = ({ name, subtexts, children }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="inherit" gutterBottom>
          {name}
        </Typography>
        {subtexts &&
          subtexts.map((text, idx) => (
            <Typography key={idx} variant="inherit" color="primary">
              <small>{text}</small>
            </Typography>
          ))}
      </TableCell>
      <TableCell align="right">{children}</TableCell>
    </TableRow>
  );
};

const pauseUserRecurringTransfers = async (userId, recurringTransferId) => {
  const body = JSON.stringify({
    enabled: false,
  });

  const url = `/api/v1/users/${userId}/recurringTransfers/${recurringTransferId}`;
  const resp = await fetch('PUT', url, body);
  const respBody = await resp.json();

  if ('errors' in respBody) {
    return alert(
      `Could not pause user's auto-add cash. ${JSON.stringify(respBody.errors)}`
    );
  }

  alert(`This user's auto-add cash was successfully paused`);
  window.location.reload();
};

const renderPauseButton = (userId, recurringTransferId) => {
  return (
    <TableRow>
      <TableCell />
      <TableCell align="right">
        <Typography variant="inherit" gutterBottom>
          <br />
          <PauseButton
            userId={userId}
            recurringTransferId={recurringTransferId}
            onClick={pauseUserRecurringTransfers}
          />
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const noValue = 'N/A';
const StatusTable = ({ account, id, adminInCorrectGroup }) => {
  const {
    enabled = noValue,
    interval = noValue,
    amount: { currency = noValue, value = noValue } = {},
    startDate = noValue,
    created,
  } = account || {};
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StatusRow name="Enabled">{String(enabled)}</StatusRow>
          <StatusRow name="Interval">{interval}</StatusRow>
          <StatusRow name="Amount">{value}</StatusRow>
          <StatusRow name="Currency">{currency}</StatusRow>
          <StatusRow name="Start Date">{startDate}</StatusRow>
          <StatusRow name="Created">
            {created ? parseDateTime(created) : noValue}
          </StatusRow>
          {adminInCorrectGroup && account.recurringTransferId ? (
            renderPauseButton(id, account.recurringTransferId)
          ) : (
            <span />
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

const RecurringTransfersOverview = ({ data, userId, adminInCorrectGroup }) => {
  const {
    recurringTransfer: { updates: [latestUpdate = {}] = [] } = {},
    recurringTransfer,
  } = data;
  return (
    <React.Fragment>
      <Grid item xs={12} md={10} lg={8} xl={6}>
        <Typography variant="subtitle1" gutterBottom>
          auto-add cash Status
        </Typography>

        <StatusTable
          id={userId}
          account={{
            created: latestUpdate.publishedAt,
            ...recurringTransfer,
            ...latestUpdate.data,
          }}
          adminInCorrectGroup={adminInCorrectGroup}
        />
      </Grid>
      <Grid item>
        <br />
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Recent Events
        </Typography>
        <Grid item xs={10}>
          <PageableTable
            headers={['Event Type', 'Details', 'Date', 'Initiated By', 'Reason']}
            apiPath={`/api/v1/users/${userId}/debit/recurringTransfers`}
            parseData={(data) => {
              const {
                recurringTransfer: { updates: events = [], total = 0 } = {},
              } = data || {};
              return {
                total,
                events: events.map((e, idx) => ({
                  key: idx,
                  'Event Type': e.description,
                  Details: `${e.data.amount.value} ${e.data.interval} ${
                    e.data.enabled ? 'enabled' : 'paused'
                  } ${e.data.startDate}`,
                  Date: parseDateTime(e.publishedAt),
                  'Initiated By': `${e.data.initiatedBy ? e.data.initiatedBy : ''}`,
                  Reason: `${e.data.reason ? e.data.reason : ''}`,
                })),
              };
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const RecurringTransfersError = (props) => {
  return (
    <Grid item>
      <Typography gutterBottom>
        Couldn&apos;t find auto-add cash data for this user.
      </Typography>
    </Grid>
  );
};

const RecurringTransfers = (props) => {
  const { data: recurringTransfers } = useDataApi(
    getUserRecurringTransfers(props.match.params.userId),
    init
  );
  const { data: adminGroups } = useDataApi(getAdminGroups(), { groups: [] });
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={24}
    >
      {recurringTransfers.errors ? (
        <RecurringTransfersError />
      ) : (
        <RecurringTransfersOverview
          data={recurringTransfers}
          userId={props.match.params.userId}
          adminInCorrectGroup={adminInCorrectGroup(
            adminGroups.groups,
            pauseAdminGroups
          )}
        />
      )}
    </Grid>
  );
};

export default RecurringTransfers;
