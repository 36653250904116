export const OKTA_GROUPS = {
  CX_AGENT_LEVEL_1: 'CX Agent Tier 1',
  CX_AGENT_LEVEL_2: 'CX Agent Tier 2',
  CX_AGENT_LEVEL_3: 'CX Agent Tier 3',
};

export const LOCK_USER = OKTA_GROUPS.CX_AGENT_LEVEL_1;

export const adminInCorrectGroup = (groups, expectedGroups) => {
  if (groups) {
    return (
      groups && groups.filter((g) => expectedGroups.includes(g)).length > 0
    );
  } else {
    return false;
  }
};
