import ArrowBack from '@material-ui/icons/ArrowBack';
import * as shortcuts from 'stash/utils/shortcuts';
import { formatReferralQuery } from 'stash/utils/formatReferralQuery';

const BACK_LABEL_AA = 'Back to AA';
const BACK_LABEL_USER_HUB = 'Back to User Hub';

export const getBackLink = ({ zendeskTicket, zendeskUser, origin }) => {
  const env = process.env.REACT_APP_ENV;
  const isProd = env?.toLowerCase() === 'prod';
  const fromUserHub = origin === 'user-hub';
  const userHubEnv = isProd ? '' : `-${env}`;
  const title = fromUserHub ? BACK_LABEL_USER_HUB : BACK_LABEL_AA;

  return {
    title,
    getPath: (id) => {
      const activeAdminPath = `https://${env}.stsh.io/admin/users/${id}`;
      const userHubPath = `https://cx${userHubEnv}.stsh.io/user/id/${id}`;
      const path = fromUserHub ? userHubPath : activeAdminPath;

      const queryString = formatReferralQuery({
        path,
        items: { zendeskTicket, zendeskUser },
      });

      return `${path}${queryString}`;
    },
    shortcut: shortcuts.BACK,
    icon: ArrowBack,
  };
};

export const getExternalPages = (props) => [getBackLink(props)];

export const NavGroup = {
  DEBIT: 'Stash Banking',
  AUTO_STASH: 'Recurring transactions',
};

export const InternalNav = [
  {
    title: 'Bank Account',
    getPath: (id) => `/users/${id}/debit`,
    shortcut: '',
    group: NavGroup.DEBIT,
  },

  {
    title: 'Bill Pay',
    getPath: (id) => `/users/${id}/bill-pay`,
    shortcut: '',
    group: NavGroup.DEBIT,
  },
  {
    title: 'Mobile Check Deposits',
    getPath: (id) => `/users/${id}/mrdc`,
    shortcut: '',
    group: NavGroup.DEBIT,
  },
  {
    title: 'Direct Deposit',
    getPath: (id) => `/users/${id}/direct-deposit`,
    shortcut: '',
    group: NavGroup.DEBIT,
  },
  {
    title: 'Partitions',
    getPath: (id) => `/users/${id}/partitions`,
    shortcut: '',
    group: NavGroup.DEBIT,
  },
  {
    title: 'Stock-Back Card',
    getPath: (id) => `/users/${id}/payment-instruments`,
    shortcut: '',
    group: NavGroup.DEBIT,
  },
  {
    title: 'Auto-invest',
    getPath: (id) => `/users/${id}/auto-invest`,
    shortcut: '',
    group: NavGroup.AUTO_STASH,
  },
  {
    title: 'Round-ups',
    getPath: (id) => `/users/${id}/round-ups`,
    shortcut: '',
    group: NavGroup.AUTO_STASH,
  },
  {
    title: 'Smart-Stash',
    getPath: (id) => `/users/${id}/smart-stash`,
    shortcut: '',
    group: NavGroup.AUTO_STASH,
  },
  {
    title: 'Auto-add cash',
    getPath: (id) => `/users/${id}/auto-add-cash`,
    shortcut: '',
    group: NavGroup.AUTO_STASH,
  },
];

export const AutoStashPaths = InternalNav.filter(
  ({ group, title }) =>
    group === NavGroup.AUTO_STASH || title === 'Auto-add cash'
);

export const isOnAutoStashPath = (userId) =>
  AutoStashPaths.some(({ getPath }) =>
    window.location.pathname.startsWith(getPath(userId))
  );
