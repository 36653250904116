import { DateTime } from 'luxon';

const allStatusAndTypes = {
  checkingTransaction: {
    types: [
      'purchase',
      'refund',
      'atmWithdrawal',
      'tellerCashWithdrawal',
      'networkCardLoad',
      'achOut',
      'cashReload',
      'peerTransfer',
      'purseTransfer',
      'achIn',
      'adjustment',
      'partnerTransferIn',
      'partnerTransferOut',
      'fee',
      'other',
      'promo',
      'mrdc',
      'billPay',
      'disbursement',
    ],
    statuses: [
      'cleared',
      'pending',
      'declined',
      'removed',
      'expired',
      'completed',
      'approved',
      'onHold',
      'rejected',
      'canceled',
      'reversed',
    ],
  },
  checkingTransferInitiated: {
    types: ['achIn', 'achOut'],
    statuses: ['initiated'],
  },
  checkingTransferDisallowed: {
    types: ['achIn', 'achOut'],
    statuses: ['disallowed'],
  },
  checkingTransferFailed: {
    types: ['achPull', 'achOut'],
    statuses: ['failed'],
  },
  checkingTransferPending: {
    types: ['achPull', 'achOut'],
    statuses: ['pending'],
  },
  checkingTransferPosted: {
    types: ['achPull', 'achOut'],
    statuses: ['posted'],
  },
  checkingTransferReturned: {
    types: ['achPull', 'achOut'],
    statuses: ['returned'],
  },
  orcaTransferAcknowledged: {
    types: ['achIn', 'achOut'],
    statuses: ['acknowledged'],
  },
  orcaTransferRejected: {
    types: ['achIn', 'achOut'],
    statuses: ['disallowed'],
  },
  orcaTransferReviewInitiated: {
    types: ['achIn', 'achOut'],
    statuses: ['underReview'],
  },
};

const allUniqueVals = (statusOrType) => {
  let vals = {};
  for (const event in allStatusAndTypes) {
    for (const val of allStatusAndTypes[event][statusOrType]) vals[val] = false;
  }
  return vals;
};

export const statusOpts = allUniqueVals('statuses');
export const typeOpts = allUniqueVals('types');
export const defaultStartDate = DateTime.local().minus({ months: 6 });
export const defaultEndDate = DateTime.local();
export const defaultSortedDate = 'desc';
export const defaultPopoverOpen = false;
