import { STASH_INIT } from 'stash/actions/init';
import { useState, useEffect } from 'react';

const NO_CONNECTION_MSG = `Couldn't connect to API. Start the API server.`;

export const useApi = (options, initialState) => {
  const { method, path, body } = options;
  const [state, setState] = useState(initialState);
  useEffect(
    () => {
      fetch(method, path, body).then((res) =>
        res.json().then((body) => {
          return setState(body);
        })
      );
    },
    // eslint-disable-next-line
    options
  );

  return state;
};

export const useDataApi = (requestData, initialData) => {
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(requestData.url);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(
    () => {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);

        try {
          const result = await fetch(requestData.method, requestData.url);
          setData(await result.json());
        } catch (error) {
          setIsError(true);
        }

        setIsLoading(false);
      };

      fetchData();
    },
    // eslint-disable-next-line
    [url, requestData.method]
  );

  return { data, isLoading, isError, doFetch: setUrl };
};

export const fetch = (method, url, body) => {
  return window.fetch(url, {
    method,
    body,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export default (store) => (next) => (action) => {
  // Test API connection in development
  if (process.env.NODE_ENV !== 'production' && action.type === STASH_INIT) {
    fetch('GET', '/api/v1').then((res) => {
      if (!res.ok) throw new Error(NO_CONNECTION_MSG);
    });
  }

  // Middleware
  if (action.api) {
    const { method, body, path } = action.payload.request;
    const url = `${path}`;
    next({ ...action, status: 'REQUEST' });
    return fetch(method, url, body).then((res) =>
      res.json().then((body) => {
        next({ ...action, status: 'RESPONSE' });
        return res.ok ? Promise.resolve(body) : Promise.reject(body);
      })
    );
  }
  next(action);
};
