import { SET_MENU_OPEN } from 'stash/actions/modal';

const init = {
  menuOpen: false,
};

const modal = (state = init, action) => {
  switch (action.type) {
    case SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.payload.enabled,
      };
    default:
      return state;
  }
};

export default modal;
