import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PartitionsList from './PartitionsList';
import BalanceSummary from './BalanceSummary';
import { fetch } from 'stash/middleware/fetch';
import { CircularProgress } from '@material-ui/core';
import { getUserDebit } from '../../api/UserDebit';

const Loading = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10vh',
      }}
    >
      <CircularProgress />
    </div>
  );
};

const Partitions = ({ userId }) => {
  const [account, setAccount] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const userDebitResp = await fetch(
          getUserDebit(userId).method,
          getUserDebit(userId).path
        );
        const userDebit = await userDebitResp.json();
        const account = userDebit.account;
        setAccount(account);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
        setError(e);
      }
    })();
  }, [fetch, userId]);

  if (loading) {
    return <Loading />;
  } else if (error) {
    return <Typography>An Error Occurred</Typography>;
  } else {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={5} xl={5}>
          <Typography variant="subtitle1" gutterBottom>
            Partitions
          </Typography>
          <PartitionsList partitions={account.partitions} />
        </Grid>
        <Grid item xs={12} sm={12} lg={7} xl={7}>
          <Typography variant="subtitle1" gutterBottom>
            Balance Summary
          </Typography>
          <BalanceSummary account={account} />
        </Grid>
      </Grid>
    );
  }
};

export default Partitions;
