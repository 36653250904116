import React, { Component } from 'react';
import { fetch } from 'stash/middleware/fetch';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { TransactionsTable } from '../checking/TransactionsTable';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const getEventByEventId = (userId, eventId) => ({
  method: 'GET',
  path: `/api/v1/users/${userId}/debit/transactions/${eventId}`,
});

const getEventByTransactionId = (userId, transactionId) => ({
  method: 'GET',
  path: `/api/v1/users/${userId}/debit/transactionsByTransactionId/${transactionId}`,
});

function isInstantWithdrawal(cardTransactionData) {
  var instantWithdrawalDescription = 'stash instant withdrawal'.toUpperCase();
  return (
    cardTransactionData['cardAcceptor']['merchantName'].toUpperCase() ===
    instantWithdrawalDescription
  );
}

function amountValue(amount) {
  return parseFloat(amount['amount']);
}

function amountReceivedValue(amount) {
  var instantWithdrawalFeePctg = 0.01;
  var amount = amountValue(amount);
  return amount - amount * instantWithdrawalFeePctg;
}

function feeAmountValue(amount) {
  return amountValue(amount) - amountReceivedValue(amount);
}

function roundCurrency(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

function amountDetailsWithFee(amount, cardTransactionData) {
  if (!isInstantWithdrawal(cardTransactionData)) {
    return amount;
  } else {
    return {
      amountWithdrawn: {
        value: roundCurrency(amountValue(amount)),
        currency: 'USD',
      },
      instantWithdrawalFee: {
        amount: {
          value: roundCurrency(feeAmountValue(amount)),
          currency: 'USD',
        },
        percentage: '1%',
      },
      amountReceived: {
        value: roundCurrency(amountReceivedValue(amount)),
        currency: 'USD',
      },
    };
  }
}

function formatJson(key, event) {
  if (key === 'amount' && event.data['cardTransactionData']) {
    var cardTransactionData = event.data['cardTransactionData'];
    var data = amountDetailsWithFee(event.data[key], cardTransactionData);
    return JSON.stringify(data, null, 2);
  } else {
    return JSON.stringify(event.data[key], null, 2);
  }
}

class TransactionShow extends Component {
  state = { event: null, relatedEvents: [], error: null, dispute: null };

  async fetchPageData() {
    const getEvent = this.props.fetchByTransactionId
      ? getEventByTransactionId
      : getEventByEventId;

    const transactionIdKey = this.props.fetchByTransactionId
      ? this.props.match.params.transactionId
      : this.props.match.params.eventId;

    const debitResp = await fetch(
      getEvent(this.props.match.params.userId, transactionIdKey).method,
      getEvent(this.props.match.params.userId, transactionIdKey).path
    );
    const resp = await debitResp.json();

    if (resp.errors) {
      this.setState({
        error: resp.errors,
      });
    } else {
      this.setState({
        event: resp.event,
        relatedEvents: resp.relatedEvents,
        dispute: resp.dispute,
      });
    }
  }

  async componentDidUpdate(prevProp) {
    if (this.props !== prevProp) {
      this.fetchPageData();
    }
  }

  async componentDidMount() {
    this.fetchPageData();
  }

  dataTable() {
    return (
      <div>
        <Chip label={this.state.event.event_type} />
        <br />
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Event Data
        </Typography>

        <Paper>
          <Table size="small">
            <TableBody>
              {Object.keys(this.state.event.data)
                .sort()
                .map((key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>
                        <pre>{formatJson(key, this.state.event)}</pre>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }

  dispute() {
    return (
      <div>
        <br />
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Dispute
        </Typography>
        <Paper>
          <Table size="small">
            <TableBody>
              {Object.keys(this.state.dispute.data)
                .sort()
                .map((key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>
                        <pre>
                          {JSON.stringify(
                            this.state.dispute.data[key],
                            null,
                            2
                          )}
                        </pre>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }

  error() {
    return (
      <div>
        <Typography variant="subtitle1" gutterBottom>
          There was an error: {JSON.stringify(this.state.error)}
        </Typography>
      </div>
    );
  }

  relatedEvents() {
    return (
      <div>
        <br />
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Related Events
        </Typography>
        <TransactionsTable
          transactions={this.state.relatedEvents}
          userId={this.props.match.params.userId}
        />
      </div>
    );
  }

  render() {
    if (this.state.error) {
      return (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              {this.error()}
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              {this.state.event && this.state.event.data
                ? this.dataTable()
                : null}
              {this.state.dispute ? this.dispute() : null}
              {this.state.relatedEvents && this.state.relatedEvents.length
                ? this.relatedEvents()
                : null}
            </Grid>
          </Grid>
          <br />
          <Typography variant="inherit">
            <Link
              to={`/users/${this.props.match.params.userId}/transaction-details`}
              component={RouterLink}
            >
              All Transactions
            </Link>
          </Typography>
        </React.Fragment>
      );
    }
  }
}

export default TransactionShow;
