import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

class CloseAccountButton extends Component {
  constructor(props) {
    super(props);
    this.account = props.account;
    this.userId = props.userId;
    this.onClick = props.onClick;
  }

  render() {
    return (
      <Button variant="contained" color="secondary" onClick={this.onClick}>
        Close Account
      </Button>
    );
  }
}

export { CloseAccountButton };
