import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import { DateTime } from 'luxon';

export class FilterDrawer extends Component {
  constructor(props) {
    super(props);
    this.setStatuses = props.setStatuses;
    this.setTypes = props.setTypes;
    this.setStartDate = props.setStartDate;
    this.setEndDate = props.setEndDate;
    this.handleChangeFilter = props.handleChangeFilter;
  }

  state = {
    open: false,
    statusOpts: this.props.statusOpts,
    typeOpts: this.props.typeOpts,
    startDate: this.props.startDate,
    endDate: this.props.endDate,
  };

  toggleDrawer = (open) => {
    this.setState({ open: open });
  };

  handleStatusCheck = (status) => (checkbox, checked) => {
    let newState = this.state;
    newState.statusOpts[status] = checked;
    this.setState(newState);
    this.setStatuses(newState.statusOpts);
    this.handleChangeFilter();
  };

  handleTypeCheck = (type) => (checkbox, checked) => {
    let newState = this.state;
    newState.typeOpts[type] = checked;
    this.setState(newState);
    this.setTypes(newState.typeOpts);
    this.handleChangeFilter();
  };

  clearFilters = () => {
    let newState = this.state;
    let newTypeOpts = { ...newState.typeOpts };
    for (const key in newTypeOpts) newTypeOpts[key] = false;
    let newStatusOpts = { ...newState.statusOpts };
    for (const key in newStatusOpts) newStatusOpts[key] = false;
    newState.typeOpts = newTypeOpts;
    newState.statusOpts = newStatusOpts;
    this.setState(newState);
    this.setTypes(newTypeOpts);
    this.setStatuses(newStatusOpts);
    this.handleChangeFilter();
  };

  handleDateChange = (dateType, newDate, setFunc) => {
    this.setState({ [dateType]: newDate }, () => {
      setFunc(newDate);
      if (this.state.startDate <= this.state.endDate) {
        this.handleChangeFilter();
      } else {
        alert('End Date is before Start Date');
      }
    });
  };

  handleStartDateChange = (event) => {
    const newStartDate = DateTime.fromISO(event.target.value);
    this.handleDateChange('startDate', newStartDate, this.setStartDate);
  };

  handleEndDateChange = (event) => {
    const newEndDate = DateTime.fromISO(event.target.value);
    this.handleDateChange('endDate', newEndDate, this.setEndDate);
  };

  render() {
    const styledToolBar = {
      display: 'block',
      textAlign: 'right',
    };

    const lessPadding = {
      paddingTop: '0',
      paddingBottom: '0',
    };

    const noMarginRight = {
      marginRight: '0',
    };

    const statusText = (display) => {
      return {
        display: display,
        width: '100%',
        height: '0',
        textAlign: 'center',
      };
    };

    const innerBox = {
      width: '100%',
      top: '10px',
      left: 0,
      position: 'absolute',
    };

    const clearButton = {
      textDecoration: 'underline',
      cursor: 'pointer',
    };

    const statusTextDisplay = () => {
      const typeKeys = Object.keys(this.state.typeOpts);
      const statusKeys = Object.keys(this.state.statusOpts);
      const hide =
        typeKeys.every((k) => this.state.typeOpts[k] === false) &&
        statusKeys.every((k) => this.state.statusOpts[k] === false);
      return hide ? 'none' : 'inline-block';
    };

    const enabledFilters = (opts) => {
      return Object.keys(opts).filter((k) => opts[k] === true);
    };

    const filterList = (
      <div>
        <List>
          <ListItem key="status">
            <ListItemText primary="Status" align="left" />
          </ListItem>
          {Object.keys(this.state.statusOpts)
            .sort()
            .map((status) => (
              <ListItem key={status} style={lessPadding}>
                <ListItemIcon style={noMarginRight}>
                  <Checkbox
                    onChange={this.handleStatusCheck(status)}
                    value="checkedB"
                    color="primary"
                    style={{ padding: '5px' }}
                    checked={this.state.statusOpts[status]}
                  />
                </ListItemIcon>
                <ListItemText primary={status} />
              </ListItem>
            ))}
        </List>
        <Divider />
        <List>
          <ListItem key="status">
            <ListItemText primary="Type" align="left" />
          </ListItem>
          {Object.keys(this.state.typeOpts)
            .sort()
            .map((type) => (
              <ListItem key={type} style={lessPadding}>
                <ListItemIcon style={noMarginRight}>
                  <Checkbox
                    onChange={this.handleTypeCheck(type)}
                    value="checkedB"
                    color="primary"
                    style={{ padding: '5px' }}
                    checked={this.state.typeOpts[type]}
                  />
                </ListItemIcon>
                <ListItemText primary={type} />
              </ListItem>
            ))}
        </List>
        <Divider />
      </div>
    );

    const datePickers = () => {
      return (
        <form noValidate style={{ display: 'inline' }}>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            defaultValue={this.state.startDate.toISODate()}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleStartDateChange}
          />
          &nbsp;&nbsp;&nbsp;
          <TextField
            id="date"
            label="End Date"
            type="date"
            defaultValue={this.state.endDate.toISODate()}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleEndDateChange}
          />
        </form>
      );
    };

    return (
      <Toolbar style={styledToolBar}>
        <Typography
          inline={true}
          color="primary"
          variant="subtitle1"
          style={statusText(statusTextDisplay())}
        >
          <div style={innerBox}>
            {enabledFilters(this.state.statusOpts).length > 0
              ? ` status: ${enabledFilters(this.state.statusOpts).join(', ')}`
              : ''}
            {enabledFilters(this.state.typeOpts).length > 0
              ? ` types: ${enabledFilters(this.state.typeOpts).join(', ')}`
              : ''}
            {enabledFilters(this.state.statusOpts.length > 0) ||
            enabledFilters(this.state.typeOpts).length > 0 ? (
              <div>
                <Link
                  color="primary"
                  style={clearButton}
                  onClick={this.clearFilters}
                >
                  clear
                </Link>
              </div>
            ) : null}
          </div>
        </Typography>
        {datePickers()}
        <Tooltip title="Filter" align="right">
          <IconButton
            aria-label="Filter"
            aria-haspopup="true"
            onClick={() => {
              this.toggleDrawer(true);
            }}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>

        <Drawer
          anchor="right"
          open={this.state.open}
          onClose={() => {
            this.toggleDrawer(false);
          }}
        >
          <div tabIndex={0} role="button">
            {filterList}
          </div>
        </Drawer>
      </Toolbar>
    );
  }
}
