const uuidRegex = /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/g;

export const extractUUIDs = (value) => {
  const amendedString = value.replace(uuidRegex, 'uuid');
  const uuidArr = value.match(uuidRegex);
  const uuids = uuidArr?.join(',');

  return {
    amendedString,
    uuidArr,
    uuids,
  };
};
