import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, ownProps) => ({
  nightMode: state.theme.nightMode,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
