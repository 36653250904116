import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useDataApi } from '../../middleware/fetch';
import { parseDateTime } from 'stash/components/utils/dateTime';
import { Loader } from 'stash/components/utils/Loader';
import { NotFound } from 'stash/components/utils/NotFound';
import get from 'lodash.get';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import Chip from '@material-ui/core/Chip';

const veryRed = red[500];
const veryGreen = green[500];

const wrapPre = {
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
};

const EligibilityInfo = (props) => {
  const eligibilityInfo = get(props, 'eligibilityData.eligibilityInfo', []);
  const prerequisitesLoading = get(props, 'mrdcPrerequisitesLoading');
  const prerequisitesData = get(props, 'prerequisitesData', []);
  const errors = get(props, 'info.errors', '');

  if (!eligibilityInfo)
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={12}>
                Could not get MRDC eligibility due to error: <br />
                <pre style={wrapPre}>
                  {errors ? JSON.stringify(errors, null, 2) : 'Unknown error'}
                </pre>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Paper>
    );

  return (
    <div>
      <Paper>
        <Table>
          <TableHead>
            {eligibilityInfo.status && (
              <TableRow>
                <TableCell>Eligibility</TableCell>
                <TableCell align="right">
                  <Typography
                    variant="inherit"
                    gutterBottom
                    style={{
                      color:
                        eligibilityInfo.status === 'eligible'
                          ? veryGreen
                          : veryRed,
                    }}
                  >
                    <Chip
                      label={eligibilityInfo.status.toUpperCase()}
                      style={{
                        color:
                          eligibilityInfo.status === 'eligible'
                            ? veryGreen
                            : veryRed,
                      }}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
        </Table>
      </Paper>
      {eligibilityInfo.status === 'eligible' && (
        <div>
          {prerequisitesLoading && <Loader />}
          {!prerequisitesLoading && (
            <div>
              <br />
              <Typography variant="caption" gutterBottom>
                This user is eligible, but they must also meet the following
                prerequisites to use MRDC.
              </Typography>
              <PrerequisitesInfo prerequisitesData={prerequisitesData} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const PrerequisitesInfo = (props) => {
  const prerequisitesInfo = get(
    props,
    'prerequisitesData.prerequisitesInfo',
    []
  );
  const errors = get(props, 'info.errors', '');

  if (!prerequisitesInfo.length)
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={12}>
                Could not get MRDC prerequisites due to error: <br />
                <pre style={wrapPre}>
                  {errors ? JSON.stringify(errors, null, 2) : 'Unknown error'}
                </pre>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Paper>
    );

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Prerequisite</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {prerequisitesInfo &&
            prerequisitesInfo.map((info, idx) => {
              return (
                <TableRow key={idx}>
                  <TableCell width="70%">
                    <Typography gutterBottom>{info.type}</Typography>
                    {info.type === 'phoneVerification' && (
                      <Typography color={'primary'}>
                        User has verified their phone via text message or phone
                        call
                      </Typography>
                    )}
                    {info.type === 'terms' && (
                      <Typography color={'primary'}>
                        User has agreed to MRDC Terms and Conditions
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="inherit"
                      gutterBottom
                      style={{ color: info.completed ? veryGreen : veryRed }}
                    >
                      <Chip
                        label={info.completed ? 'Completed' : 'Not Completed'}
                        style={{ color: info.completed ? veryGreen : veryRed }}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};

const MRDC = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRows] = useState(25);
  const [rows] = useState({});

  const userId = props.match.params.userId;

  const { data: mrdcEligibilityData, isLoading: mrdcEligibilityLoading } =
    useDataApi({
      method: 'GET',
      url: `/api/v1/users/${userId}/debit/mrdc/eligibility`,
    });

  const { data: mrdcPrerequisitesData, isLoading: mrdcPrerequisitesLoading } =
    useDataApi({
      method: 'GET',
      url: `/api/v1/users/${userId}/debit/mrdc/prerequisites`,
    });

  const {
    data: depositData,
    isLoading: checkIsLoading,
    isError: checkError,
    doFetch: checkDoFetch,
  } = useDataApi(
    {
      method: 'GET',
      url: `/api/v1/users/${userId}/debit/checkDeposits?limit=${rowsPerPage}&offset=${
        page * rowsPerPage
      }`,
    },
    { rows }
  );

  const totalCount = parseFloat(depositData.total);

  const getMoreTransactions = (page) => {
    checkDoFetch({
      method: 'GET',
      url: `/api/v1/users/${
        props.match.params.userId
      }/debit/checkDeposits?limit=${rowsPerPage}&offset=${page * rowsPerPage}`,
    });
  };

  const handleChangePage = (event, page) => {
    setPage(page);
    getMoreTransactions(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRows(event.target.value);
    getMoreTransactions(event.target.value);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={6} xl={6}>
          {mrdcEligibilityLoading && <Loader />}
          {!mrdcEligibilityLoading && (
            <div>
              <Typography variant="subtitle1" gutterBottom={15}>
                Mobile Check Deposit Eligibility
              </Typography>
              <Typography variant="caption">
                A user is eligible for MRDC if their account is healthy and they
                meet the internal MRDC eligibility criteria.
              </Typography>
              <EligibilityInfo
                eligibilityData={mrdcEligibilityData}
                prereqsLoading={mrdcPrerequisitesLoading}
                prerequisitesData={mrdcPrerequisitesData}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <br />
          <br />
          <Typography variant="subtitle1" gutterBottom={15}>
            Recent Check Deposit Events
          </Typography>
          <Paper>
            <Table>
              <TableHead>
                {checkIsLoading && <Loader />}
                {checkError && <h1>We're sorry an error occurred</h1>}
                {!get(depositData, 'total') ? (
                  <NotFound type={'Recent Check Deposits'} />
                ) : (
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Sub Status</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Available On</TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {depositData.deposits &&
                  depositData.deposits.map((row, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell>
                          {row.amount && '$' + row.amount.value}
                        </TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.subStatus}</TableCell>
                        <TableCell>{parseDateTime(row.publishedAt)}</TableCell>
                        <TableCell>{row.availableOn}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {depositData.deposits ? (
                    <TablePagination
                      rowsPerPageOptions={[25, 50, 100]}
                      colSpan={3}
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  ) : null}
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MRDC;
