import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { StatusRow } from './StatusRow';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { parseDateTime } from 'stash/components/utils/dateTime';
import { CloseAccountButton } from './CloseAccountButton';
import { CloseAttempts } from './CloseAttempts';
import { fetch } from '../../middleware/fetch.js';
import Paper from '@material-ui/core/Paper';
import Money from '../utils/Money';
const closeAccountAdminGroup = 'CX Agent Tier 2';

const areYouSureMessage = `
    Placing a users account into spend down is permanent. 
    Are you sure you have confirmed that the user is aware and 
    knows that the account will be in a spend down?`;

const AccountStatus = {
  Pending: 'pending',
  Restricted: 'restricted',
  Normal: 'normal',
  Closed: 'closed',
  Locked: 'locked',
  Unknown: 'unknown',
};

const AccountStatusReason = {
  UnderReview: 'underreview',
  PotentialFraud: 'potentialfraud',
};

const AccountCloseReasons = {
  InactiveAccount: 'inactiveAccount',
  RequestedByUser: 'requestedByUser',
  RequestedByCx: 'requestedByCx',
};

class StatusTable extends Component {
  constructor(props) {
    super(props);
    this.account = props.account;
    this.id = props.id;
    this.accountReferenceNumber = this.account.accountReferenceNumber;
    this.availableBalance = this.account.availableBalance;
    this.accountStatus = this.account.accountStatus;
    this.applicationStatus = this.account.applicationStatus;
    this.closeEvent = this.account.closeEvent;
    this.state = { closeAttempts: [] };
    this.closeAccount = this.closeAccount.bind(this);
    this.partitionsBalance = this.account.balanceSummary.partitionsBalance;
    this.spendableBalance = this.account.balanceSummary.spendableBalance;
  }

  async componentDidMount() {
    const url = `/api/v1/users/${this.id}/debit/account/closeAttempts`;
    const resp = await fetch('GET', url);
    const body = await resp.json();
    this.setState({ closeAttempts: body.closeAttempts });
  }

  hasCloseAttempts() {
    return this.state.closeAttempts.length > 0;
  }

  adminInCorrectGroup() {
    const adminGroups = this.props.adminGroups;

    if (adminGroups) {
      const groups = adminGroups.groups;
      return (
        groups && groups.filter((g) => g === closeAccountAdminGroup).length > 0
      );
    } else {
      return false;
    }
  }

  async closeAccount() {
    const body = JSON.stringify({ accountUUID: this.account.id });
    const shouldCloseAccount = window.confirm(areYouSureMessage);
    const url = `/api/v1/users/${this.id}/debit/account/close`;

    if (shouldCloseAccount) {
      const resp = await fetch('POST', url, body);
      const respBody = await resp.json();

      if ('errors' in respBody) {
        return alert(
          `Could not put account into spend down. Account must be in a normal state.\nError:\n${JSON.stringify(
            respBody.errors
          )}`
        );
      }

      if ('closeAttempts' in respBody) {
        alert('This account was successfully placed into spend down.');
        window.location.reload();
      }
    }
  }

  accountStatusColor(status, statusReasons) {
    const red = {
      backgroundColor: '#f50057',
      color: '#fff',
    };

    const yellow = {
      backgroundColor: '#FFFC00',
      color: '#000',
    };

    const green = {
      backgroundColor: '#7df700',
      color: '#000',
    };

    const restrictedColor = () => {
      return statusReasons.includes(AccountStatusReason.UnderReview) ||
        statusReasons.includes(AccountStatusReason.PotentialFraud)
        ? yellow
        : red;
    };

    switch (status) {
      case AccountStatus.Pending:
        return yellow;
      case AccountStatus.Restricted:
        return restrictedColor();
      case AccountStatus.Normal:
        return green;
      case AccountStatus.Closed:
        return red;
      case AccountStatus.Locked:
        return red;
      case AccountStatus.Unknown:
        return red;
      default:
        return red;
    }
  }

  closeAccountButtonEnabled() {
    return (
      this.adminInCorrectGroup() &&
      !this.hasCloseAttempts() &&
      this.accountStatus.status === AccountStatus.Normal
    );
  }

  maybeClosedAccountReason() {
    const closeReasonText = (closeEvent) => {
      const date = parseDateTime(
        new Date(closeEvent.publishedAt).toISOString()
      );

      switch (closeEvent.closeReason) {
        case AccountCloseReasons.InactiveAccount:
          return `This account was placed into Spend down by Stash due to inactivity on ${date}`;
        case AccountCloseReasons.RequestedByUser:
          return `This account was placed into Spend down by the User on ${date}`;
        case AccountCloseReasons.RequestedByCx:
          return `This account was closed by a Stash CX Agent on ${date}`;
        default:
          return;
      }
    };

    if (
      this.closeEvent &&
      this.closeEvent.closeReason &&
      this.closeEvent.publishedAt
    ) {
      return (
        <Typography variant="inherit" gutterBottom color={'secondary'}>
          {closeReasonText(this.closeEvent)}
        </Typography>
      );
    }
  }

  maybeAccountCloseRow() {
    if (
      (this.closeEvent && this.closeEvent.closeReason) ||
      this.hasCloseAttempts()
    ) {
      return (
        <TableRow>
          <TableCell colSpan={2}>
            {this.maybeClosedAccountReason()}
            {this.hasCloseAttempts() && (
              <CloseAttempts closeAttempts={this.state.closeAttempts} />
            )}
          </TableCell>
        </TableRow>
      );
    }
  }

  render() {
    const accountStatus = this.props.account.accountStatus;
    return (
      <Paper>
        <Table>
          <TableBody>
            <StatusRow name="Reference No.">
              {this.accountReferenceNumber}
            </StatusRow>
            <StatusRow name="Stash Account ID.">{this.account.id}</StatusRow>
            <StatusRow
              name="Available Balance"
              subtexts={
                this.availableBalance.asOf && [
                  `As of ${parseDateTime(this.availableBalance.asOf)}`,
                ]
              }
            >
              <Money balance={this.availableBalance.amount} />
            </StatusRow>
            <StatusRow name="Partitions Balance">
              <Money balance={this.partitionsBalance} />
            </StatusRow>
            <StatusRow
              name="Application"
              subtexts={
                this.applicationStatus.esigDate && [
                  `E-signed ${parseDateTime(this.applicationStatus.esigDate)}`,
                ]
              }
            >
              {this.applicationStatus.isFounder !== undefined &&
                this.applicationStatus.isFounder && <Chip label="FOUNDER" />}
            </StatusRow>
            <StatusRow
              name="Enrollment Status"
              subtexts={
                this.applicationStatus.status && [
                  `Response Code: ${this.applicationStatus.status.code}, SubCode: ${this.applicationStatus.status.subCode}, Description: ${this.applicationStatus.status.description}`,
                ]
              }
            />
            <StatusRow
              name="Account Status"
              subtexts={[
                accountStatus.statusReasons &&
                  `Reason codes: ${accountStatus.statusReasons.join(',')}`,
                accountStatus.accountStatusChangedAt &&
                  `Account status changed at: ${parseDateTime(
                    accountStatus.accountStatusChangedAt
                  )}`,
              ]}
            >
              {accountStatus.status && (
                <Chip
                  label={accountStatus.status}
                  style={this.accountStatusColor(
                    accountStatus.status,
                    accountStatus.statusReasons
                  )}
                />
              )}
            </StatusRow>
            <StatusRow
              name="ACH Pull Status"
              subtexts={[
                this.accountStatus.achPull === undefined
                  ? 'All users who registered prior to March 4th, 2019 should have it enabled (unless explicitly disabled for fraud or other reasons).'
                  : this.accountStatus.achPull.reason &&
                    `Reason: ${this.accountStatus.achPull.reason}`,
              ]}
            >
              <Chip
                label={
                  this.accountStatus.achPull === undefined
                    ? 'Unknown'
                    : this.accountStatus.achPull.enabled
                    ? 'Enabled'
                    : 'Disabled'
                }
              />
            </StatusRow>
            <TableRow>
              <TableCell>
                <Typography variant="inherit" gutterBottom>
                  <Link
                    to={`/users/${this.id}/ach-pull`}
                    component={RouterLink}
                  >
                    ACH Pull Info
                  </Link>
                </Typography>
              </TableCell>
              <TableCell align="right" />
            </TableRow>
            {this.maybeAccountCloseRow()}
            <TableRow>
              <TableCell
                align="left"
                colSpan={2}
                style={{ paddingTop: '10px' }}
              >
                <Typography variant="inherit" gutterBottom>
                  {this.closeAccountButtonEnabled() && (
                    <CloseAccountButton
                      account={this.account}
                      userId={this.id}
                      onClick={this.closeAccount.bind(this)}
                    />
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export { StatusTable };
