import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SidebarItems from './SidebarItems';
import Hidden from '@material-ui/core/Hidden';

const styles = (theme) => ({
  drawer: {
    position: 'relative',
    width: '100%',
    zIndex: '1',
  },
  open: {
    position: 'relative',
    width: 240,
    zIndex: '1',
  },
});

const Sidebar = (props) => {
  const { menuOpen, setMenuOpen } = props;
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          classes={{
            paper: props.classes.open,
          }}
          open={menuOpen}
          onClose={handleMenuToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <SidebarItems />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          anchor="left"
          classes={{
            paper: props.classes.drawer,
          }}
          open
        >
          <SidebarItems />
        </Drawer>
      </Hidden>
    </>
  );
};

export default withStyles(styles)(Sidebar);
