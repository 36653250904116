import Sidebar from './Sidebar';
import { connect } from 'react-redux';
import { setMenuOpen } from 'stash/actions/modal';

const mapStateToProps = (state, ownProps) => ({
  menuOpen: state.modal.menuOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setMenuOpen: (enabled) => {
    return dispatch(setMenuOpen(enabled));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
