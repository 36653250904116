import { useEffect, useContext } from 'react';
import { PermissionsContext } from 'stash/components/permissionsProvider/PermissionsProvider';
import { fetch } from '../../middleware/fetch';

export const usePermissions = () => {
  const { setPermissions, ...rest } = useContext(PermissionsContext);

  useEffect(() => {
    (async () => {
      const response = await fetch('GET', '/auth/permissions');
      const permissions = await response.json();
      setPermissions(permissions);
    })();

    // eslint-disable-next-line
  }, []);

  return { setPermissions, ...rest };
};
