import React from 'react';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

const contentStyles = {
  maxWidth: '500px',
  padding: '15px',
};

const linkStyles = {
  width: '100%',
  textAlign: 'right',
  display: 'block',
};

const buttonStyle = {
  padding: '3px',
  top: '-10px',
  right: '-10px',
};

class TypePopover extends React.Component {
  close = () => {
    this.props.setPopoverOpen(false);
  };

  render() {
    return (
      <Popover
        id="type-popover"
        open={this.props.open}
        anchorEl={this.props.anchorEl}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper style={contentStyles}>
          <Typography>
            <Link color="primary" onClick={this.close} style={linkStyles}>
              <IconButton onClick={this.closeModel} style={buttonStyle}>
                <Close />
              </IconButton>
            </Link>
            {this.props.content}
          </Typography>
        </Paper>
      </Popover>
    );
  }
}

export default TypePopover;
