import React, { useState } from 'react';

export const PermissionsContext = React.createContext({
  permissions: [],
  setPermissions: () => {},
});

export const PermissionsProvider = ({ children }) => {
  const [state, setState] = useState([]);

  return (
    <PermissionsContext.Provider
      value={{
        permissions: state,
        setPermissions: setState,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
