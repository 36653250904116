import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const CheckingError = (props) => {
  return (
    <div>
      <Grid item>
        <Typography variant="subtitle1" gutterBottom>
          Error
        </Typography>
        <Typography gutterBottom>
          Couldn&apos;t find bank data for this user.
        </Typography>
        <Typography gutterBottom>
          Details: {JSON.stringify(props.error)}
        </Typography>
      </Grid>
    </div>
  );
};

export { CheckingError };
