export const TypeDefinitions = {
  achIn:
    'Any addition or subtraction of funds to a customers Stash Banking Account. Stash is the recipient. Most commonly, this is a Direct Deposit',
  achOut: 'GreenDot money movement TO an external bank account via ACH',
  achPull: 'GreenDot money movement FROM an external bank account via ACH',
  adjustment: 'Generic account balance adjustment',
  atmWithdrawal:
    'Withdrawing funds from an ATM. The amount may include any third-party fees that are assessed by the ATM operator',
  billPay: 'Spend through the Stash BillPay feature',
  cashReload:
    "Funding an account by using GreenDot's Cash Reload services (reload at the register, cash back, etc)",
  disbursement: 'Money movement related to the disbursement feature',
  fee:
    'A GreenDot or processor initiated fee charged to the account. Common fees include, ATM, purchase, and monthly maintenance fees. 3rd party ATM fees do not show up with this fee type',
  mrdc: 'Mobile Check Deposit. Depositing money using an image of a check',
  networkCardLoad:
    'Funds credited to the account using paypal, debit to debit, venmo, square cash, etc',
  other: 'Other general transaction type',
  partnerTransferIn:
    'Money movement from an external bank account via ACH, initiated by GreenDot',
  partnerTransferOut:
    'Money movement to an external bank account via ACH, initiated by GreenDot',
  peerTransfer: 'Peer-to-peer money movement',
  promo: 'Promotional credits applied to an account, performed by GreenDot',
  purchase: 'Cardholder spend activity through their card',
  purseTransfer:
    'Partitions money movement. Either a partition-to-partition or main banking account-to-partition/partition-to-main banking account transaction',
  refund: 'Merchant return credit',
  tellerCashWithdrawal: 'Withdraw from a bank teller',
};

export const StatusDefinitions = {
  achIn: {
    completed:
      "The full ACH in amount has been fully posted to the customer's account",
  },
  achOut: {
    completed:
      'The full ACH out transfer was approved, and funds were withdrawn',
    reversed:
      'The ACH transfer was returned or cancelled, and the transaction will therefore be reversed',
  },
  achPull: {},
  adjustment: {
    completed:
      'An adjustment was made to the account and the adjustment reason was included in the transaction event details',
  },
  atmWithdrawal: {
    pending:
      'The ATM withdrawal is pending authorization and has not yet been settled',
    declined:
      'The ATM withdrawal was declined at the merchant. A "Decline Reason" will be provided in the transaction event details',
    removed:
      'The authorization was removed and the withdrawal will not settle. This may occur if a pending authorization is reversed',
    expired:
      'Authorizations have a hold date, after which they will automatically expire',
    completed: 'Funds were withdrawn from an ATM or a teller in a bank',
    cleared:
      'The full ATM withdrawal amount has been authorized & has been fully posted',
  },
  billPay: {
    completed: 'A bill payment was posted to the account',
    canceled: 'User canceled the bill payment',
  },
  cashReload: {
    completed: 'A cash deposit via a retailer was posted to the account',
  },
  disbursement: {},
  fee: {
    completed:
      'A fee was applied to the account. The fee type was included in the transaction event details',
  },
  mrdc: {
    approved:
      'Submitted check image was accepted. Funds available date will be provided in the transaction event details',
    completed: 'A check image was deposited and posted to the account',
    declined: 'Deposit was declined due to a limit or violation of a rule',
    failed: 'Failed due to a system failure or offline system',
  },
  networkCardLoad: {
    completed: 'Funds were credited to the account from a cash re-load',
    pending:
      'This is a transaction that has not yet been settled, or is pending authorization',
    declined:
      'Purchase was declined at the merchant. A "Decline Reason" will be provided in the transaction event details',
    removed:
      'The authorization was removed and the withdrawal will not settle. This may occur if a pending authorization is reversed',
    expired:
      'Authorizations have a hold date, after which they will automatically expire',
    cleared:
      'The full network card load amount has been authorized & has been fully posted',
  },
  other: {
    completed: 'An unclassified transaction was completed',
  },
  partnerTransferIn: {
    completed: 'An ACH Pull transaction has been posted to the account',
    declined: 'Transfer was declined due to a limit or violation of a rule',
    failed: 'Failed due to a system failure or offline system',
  },
  partnerTransferOut: {
    declined: 'Transfer was declined due to a limit or violation of a rule',
    failed: 'Failed due to a system failure or offline system',
  },
  peerTransfer: {
    completed: 'A P2P funds transfer was posted to the account',
    declined: 'Transfer was declined due to a limit or violation of a rule',
    failed: 'Failed due to a system failure or offline system',
    canceled: 'User canceled the peer transfer',
  },
  promo: {},
  purchase: {
    completed: 'A product or service was purchased from a merchant',
    pending:
      'This is a transaction that has not yet been settled, or is pending authorization',
    declined:
      'Purchase was declined at the merchant. A "Decline Reason" will be provided in the transaction event details',
    removed:
      'The authorization was removed and the withdrawal will not settle. This may occur when a purchase at a fuel pump is abandoned, or if a pending authorization is reversed',
    expired:
      'Authorizations have a hold date, after which they will automatically expire',
  },
  purseTransfer: {
    completed:
      'Partitions money movement. Either a partition-to-partition or main banking account-to-partition/partition-to-main banking account transaction has posted',
    declined: 'Transfer was declined due to a limit or violation of a rule',
    failed: 'Failed due to a system failure or offline system',
  },
  refund: {
    pending:
      'The refund transaction has not yet settled or is pending authorization',
    declined:
      'Refund was declined at the merchant. A "Decline Reason" will be provided in the transaction event details',
    expired:
      'Authorizations have a hold date, after which they will automatically expire',
    completed: 'A payment was refunded (returned merchandise)',
    cleared:
      'The full refund amount has been authorized & has been fully posted',
  },
  tellerCashWithdrawal: {
    pending:
      'This is a cash withdrawal that has not yet been settled, or is pending authorization',
    declined:
      'Cash withdrawal was declined at the merchant. A "Decline Reason" will be provided in the transaction event details',
    removed: 'The authorization was removed and the withdrawal will not settle',
    expired:
      'Authorizations have a hold date, after which they will automatically expire',
    completed: 'Funds were withdrawn from an ATM or a teller in a bank',
    cleared:
      'The full teller cash withdrawal amount has been authorized & has been fully posted',
  },
};
