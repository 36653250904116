import React, { Component } from 'react';
import PageableTable from "../utils/PageableTable";

const AtomicEventInfo = ({
  userId
}) => {
    return (
      <div>
            <PageableTable
                headers={[
                      'Date of Submission',
                      'Distribution Type',
                       'Distribution Amount',
                      'Status',
                      'Company',
                    ]}
                apiPath={`/api/v1/users/${userId}/debit/atomic/status`}
                parseData={(data) => {
                    const { atomicEventsStatus: events = [], total = 0 } = data || {};
                      return {
                          total,
                          events: events.map((event, idx) => ({
                          key: idx,
                          'Date of Submission': event.requestDate,
                          'Distribution Type': event.distributionType,
                          'Distribution Amount': event.distributionAmount || 100,
                            'Status': event.status,
                            'Company': event.company.name,
                        })),
                      };
                    }}
            />
      </div>
    );
}

export default AtomicEventInfo;

