import { createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';

export const light = createMuiTheme({
  typography: {
    fontFamily: [
      'Walsheim',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
    fontSize: 15,
  },
  palette: {
    type: 'light',
    primary: { main: deepPurple[500] },
  },
});
