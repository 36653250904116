import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

import { useDataApi } from 'stash/middleware/fetch';
import { Loader } from 'stash/components/utils/Loader';
import { activeAdminUrlBuilder } from 'stash/components/utils';
import { setScheduleNextExecution } from 'stash/utils/SetScheduleNextExecution';

const getActiveAdminLink = (userId) =>
  activeAdminUrlBuilder({
    path: 'admin/auto_stashes',
    params: { 'q[user_uuid_eq]': userId },
  });

const recurringTransferRow = (recurringTransfer) => (
  <TableRow key={recurringTransfer.id}>
    <TableCell>{recurringTransfer.description}</TableCell>
    <TableCell>{recurringTransfer.interval}</TableCell>
    <TableCell>{recurringTransfer.amount.value}</TableCell>
    <TableCell>{recurringTransfer.status}</TableCell>
    <TableCell>
      {setScheduleNextExecution(
        recurringTransfer.enabled,
        recurringTransfer.pausedUntil,
        recurringTransfer.nextExecutionDate
      )}
    </TableCell>
    <TableCell>
      <Link to={`auto-invest/${recurringTransfer.id}`} component={RouterLink}>
        view
      </Link>
    </TableCell>
  </TableRow>
);

const SetScheduleOverviewTable = ({ recurringTransfers = [], userId }) => {
  return (
    <>
      {!recurringTransfers.length ? (
        <Typography>
          Check <a href={getActiveAdminLink(userId)}>Active Admin</a> for
          auto-invest details for this user.
        </Typography>
      ) : (
        <></>
      )}
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Frequency</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Next Execution</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{recurringTransfers.map(recurringTransferRow)}</TableBody>
        </Table>
      </Paper>
    </>
  );
};

const SetScheduleOverview = (props) => {
  const userId = props.match.params.userId;
  const { data, isLoading, isError, doFetch } = useDataApi(
    {
      method: 'GET',
      url: `/api/v1/users/${userId}/autoStash/setSchedule`,
    },
    {
      recurringTransfers: [],
    }
  );
  if (isLoading) return <Loader />;
  if (isError) return <div>Error loading user's auto-invest settings.</div>;
  return (
    <SetScheduleOverviewTable
      recurringTransfers={data.recurringTransfers}
      userId={userId}
    />
  );
};

export default SetScheduleOverview;
