// If we want to implement behaviour on a FeatureFlag it
// must be added to `flagsToRequest` in order to request the
// flag from LD for analysis.

export const flags = {
  SHOW_LEGACY_BANK_PROVIDER: 'showLegacyBankProviderBanner',
  SHOW_NEW_AUTOSTASH_TERMS: 'showNewAutostashTerminology',
};

export const flagsToRequest = {
  [flags.SHOW_LEGACY_BANK_PROVIDER]: true,
  [flags.SHOW_NEW_AUTOSTASH_TERMS]: true,
};
