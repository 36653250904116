import { createMuiTheme } from '@material-ui/core/styles';
import { cyan } from '@material-ui/core/colors';

export const dark = createMuiTheme({
  typography: {
    fontFamily: [
      'Walsheim',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
    fontSize: 15,
    subtitle1: {
      color: 'white',
    },
  },
  palette: {
    primary: { main: cyan[500] },
    type: 'dark',
  },
});
