import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import { useDataApi } from '../../middleware/fetch';

function TransferLimitsTableRow(props) {
  const { path, limits } = props;
  const bankPath = path.replace('Debit', 'Bank');

  return (
    <TableRow>
      <TableCell>{bankPath}</TableCell>
      <TableCell>
        {limits.map((limit) => {
          const { duration, amount: { value } = {} } = limit;
          return (
            <Chip
              style={{ marginRight: '10px' }}
              label={`${duration}: ${value}`}
            />
          );
        })}
      </TableCell>
    </TableRow>
  );
}

export default function TransferLimitsTable(props) {
  const { userId } = props;
  const { data } = useDataApi(
    {
      method: 'GET',
      url: `/api/v1/users/${userId}/transferLimits`,
    },
    {
      transferLimits: [],
    }
  );

  const { transferLimits = [] } = data;
  function transferlimitsTableRow() {
    return transferLimits.map((transferLimit) => (
      <TransferLimitsTableRow
        path={transferLimit.path}
        limits={transferLimit.limits}
      />
    ));
  }

  function noTransferLimitsTableRow() {
    return (
      <TableRow>
        <TableCell colSpan={2}>No transfer limits found</TableCell>
      </TableRow>
    );
  }

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Path</TableCell>
            <TableCell>Limits</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transferLimits.length === 0
            ? noTransferLimitsTableRow()
            : transferlimitsTableRow()}
        </TableBody>
      </Table>
    </Paper>
  );
}
