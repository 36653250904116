import { SET_NIGHT_MODE } from 'stash/actions/theme';

const init = {
  nightMode: false,
};

const theme = (state = init, action) => {
  switch (action.type) {
    case SET_NIGHT_MODE:
      return {
        ...state,
        nightMode: action.payload.enabled,
      };
    default:
      return state;
  }
};

export default theme;
