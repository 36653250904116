import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  InputLabel,
  FormHelperText,
  Button,
  TextField,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormik } from 'formik';
import { useFetch } from '@stashinvest/use-fetch';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Loader } from '../utils/Loader';

const SignupSchema = Yup.object().shape({
  notes: Yup.string()
    .min(3, 'Please enter more than 3 characters.')
    .required('Please tell us why you are locking the account'),
  accountStatus: Yup.string().required('Required'),
});

export const AccountStatusUpdates = ({ account, debitFetch }) => {
  const params = useParams();
  const { userId } = params;

  const { fetch: lockAccount, loading, error } = useFetch({
    method: useFetch.PUT,
    path: `/api/v1/users/${userId}/debit/account/${account.id}/status`,
  });

  const formik = useFormik({
    initialValues: {
      notes: '',
      accountStatus: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      const { notes, accountStatus } = values;
      await lockAccount({
        body: {
          reason: accountStatus,
          notes,
        },
      });
      debitFetch();
    },
  });

  return (
    <Grid item xs={3} style={{ marginLeft: 'auto' }}>
      <form onSubmit={formik.handleSubmit}>
        <InputLabel>Change Account Status</InputLabel>
        <Select
          data-testid="change-account-status"
          fullWidth
          name="accountStatus"
          id="accountStatus"
          onChange={formik.handleChange}
          value={formik.values.accountStatus}
          error={
            formik.touched.accountStatus && Boolean(formik.errors.accountStatus)
          }
        >
          <MenuItem value="">-------</MenuItem>
          <MenuItem value="potentialFirstPartyFraud">
            locked/potentialFirstPartyFraud
          </MenuItem>
          <MenuItem value="confirmedFirstPartyFraud">
            locked/confirmedFirstPartyFraud
          </MenuItem>
          <MenuItem value="potentialThirdPartyFraud">
            locked/potentialThirdPartyFraud
          </MenuItem>
          <MenuItem value="confirmedThirdPartyFraud">
            locked/confirmedThirdPartyFraud
          </MenuItem>
          <MenuItem value="potentialAccountTakeover">
            locked/potentialAccountTakeover
          </MenuItem>
          <MenuItem value="confirmedAccountTakeover">
            locked/confirmedAccountTakeover
          </MenuItem>
          <MenuItem value="potentialIdentityTheft">
            locked/potentialIdentityTheft
          </MenuItem>
          <MenuItem value="confirmedIdentityTheft">
            locked/confirmedIdentityTheft
          </MenuItem>
          <MenuItem value="potentialOtherFraud">
            locked/potentialOtherFraud
          </MenuItem>
          <MenuItem value="confirmedOtherFraud">
            locked/confirmedOtherFraud
          </MenuItem>
        </Select>
        {formik.touched.accountStatus && formik.errors.accountStatus && (
          <FormHelperText error>You must select a status</FormHelperText>
        )}

        <InputLabel style={{ marginTop: '20px' }}>Notes</InputLabel>
        <TextField
          fullWidth
          id="notes"
          name="notes"
          value={formik.values.notes}
          onChange={formik.handleChange}
          error={formik.touched.notes && Boolean(formik.errors.notes)}
          helperText={formik.touched.notes && formik.errors.notes}
        />
        <Button
          color="secondary"
          variant="contained"
          type="submit"
          style={{ marginTop: '20px' }}
        >
          Submit
        </Button>
        {loading && <Loader />}
        {error && (
          <FormHelperText error>{error.errors[0].description}</FormHelperText>
        )}
      </form>
    </Grid>
  );
};
