import React, { useState } from "react";

export const ReferralContext = React.createContext({
  zendeskTicket: null,
  zendeskUser: null,
  origin: null,
  setReferral: () => {},
});

export const ReferralProvider = ({ children }) => {
  const [state, setState] = useState({
    zendeskTicket: null,
    zendeskUser: null,
    origin: null,
  });

  return (
    <ReferralContext.Provider
      value={{
        zendeskTicket: state.zendeskTicket,
        zendeskUser: state.zendeskUser,
        origin: state.origin,
        setReferral: setState,
      }}>
      {children}
    </ReferralContext.Provider>
  );
};
