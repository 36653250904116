import { useState, useEffect } from 'react';
import { fetch } from '../../middleware/fetch';

export const useOktaProfile = () => {
  const [oktaProfile, setOktaProfile] = useState({
    email: '',
    sub: '',
    groups: [],
  });

  useEffect(() => {
    (async () => {
      const response = await fetch('GET', '/auth/okta/profile');
      const { sub, email, groups } = await response.json();
      setOktaProfile({ sub, email, groups });
    })();
  }, []);

  return { oktaProfile };
};
