import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useDataApi } from '../../middleware/fetch';
import { parseDateTime } from 'stash/components/utils/dateTime';
import { Loader } from 'stash/components/utils/Loader';
import { NotFound } from 'stash/components/utils/NotFound';
import get from 'lodash.get';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const AchPull = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRows] = useState(25);
  const [rows] = useState({});

  const { data, isLoading, isError, doFetch } = useDataApi(
    {
      method: 'GET',
      url: `/api/v1/users/${
        props.match.params.userId
      }/debit/achPullEvents?limit=${rowsPerPage}&offset=${page * rowsPerPage}`,
    },
    { rows }
  );

  const totalCount = parseFloat(data.total);
  const { events } = data;

  const getMoreTransactions = (page) => {
    doFetch({
      method: 'GET',
      url: `/api/v1/users/${
        props.match.params.userId
      }/debit/achPullEvents?limit=${rowsPerPage}&offset=${page * rowsPerPage}`,
    });
  };

  const handleChangePage = (event, page) => {
    setPage(page);
    getMoreTransactions(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRows(event.target.value);
    getMoreTransactions(event.target.value);
  };

  return (
    <React.Fragment>
      <Typography variant="subtitle1">ACH Pull History</Typography>
      <Paper>
        <Table>
          <TableHead>
            {isLoading && <Loader />}
            {isError && <h1>We're sorry an error occurred</h1>}
            {!get(data, 'events.length') && <NotFound type={'events'} />}

            <TableRow>
              <TableCell>ACH Enabled/Disabled</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Ach Variables</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events &&
              events.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell>
                      {row.enabled ? 'Enabled' : 'Disabled'}
                    </TableCell>
                    <TableCell>{parseDateTime(row.updatedAt)}</TableCell>
                    <TableCell>{row.reason}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <TableRow>
              {events ? (
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  colSpan={3}
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              ) : null}
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default AchPull;
