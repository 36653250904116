import React from 'react';
import { Typography } from '@material-ui/core';

const Money = ({ balance }) => {
  if (balance !== undefined && balance.value !== undefined) {
    balance = balance.value;
  }

  return (
    <Typography variant="inherit" gutterBottom>
      {balance !== undefined ? `$${balance.toFixed(2)}` : 'Unknown'}
    </Typography>
  );
};

export default Money;
