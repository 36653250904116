import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { fetch } from '../../middleware/fetch';
import { Loader } from 'stash/components/utils/Loader';
import get from 'lodash.get';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Money from '../utils/Money';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Permissioned } from '../permissioned';
import AtomicEventInfo from "./AtomicEvents";

class DirectDepositInfo extends Component {
  constructor(props) {
    super(props);
    this.userId = props.userId;
    this.state = {
      amount: undefined,
      date: undefined,
      active: undefined,
      latestTransaction: undefined,
      emailAddress: '',
      formSent: false,
    };
  }

  async componentDidMount() {
    const userInfoResp = await fetch(
      'GET',
      `/api/v1/users/${this.userId}/userInfo`
    );
    const ddInfoResp = await fetch(
      'GET',
      `/api/v1/users/${this.userId}/debit/directDeposit`
    );

    const {
      directDepositStatus: { active, latestTransaction },
    } = await ddInfoResp.json();
    const userInfo = await userInfoResp.json();
    const emailAddress = userInfo.email;

    this.setState({
      amount: get(latestTransaction, 'amount', undefined),
      date: get(latestTransaction, 'date', undefined),
      active: active,
      latestTransaction: latestTransaction,
      emailAddress,
    });
  }

  async sendPreFilledDDForm() {
    if (this.state.formSent) {
      alert(
        "DD Form has just been sent. We don't want the user to receive duplicate emails from us"
      );
    } else {
      if (
        window.confirm(
          `Are you sure you want to send the form to ${this.state.emailAddress}?`
        )
      ) {
        const url = `/api/v1/users/${this.userId}/debit/dd/sendForm`;
        const body = JSON.stringify({ emailAddress: this.state.emailAddress });
        const resp = await fetch('POST', url, body);
        const respBody = resp.json();

        if ('errors' in respBody) {
          return alert(
            `Could not send pre-filled form, try again.\nError:\n${JSON.stringify(
              respBody.errors
            )}`
          );
        } else {
          alert('The form has been successfully sent.');
          this.setState({ formSent: true });
        }
      }
    }
  }

  handleEmailFieldChange = (e) => {
    this.setState({ emailAddress: e.target.value });
  };

  render() {
    return (
      <div>
        {typeof this.state.active === 'undefined' && <Loader />}
        {typeof this.state.active !== 'undefined' && (
          <Paper>
            <Table>
              <TableHead />
              <TableBody>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell align="right" colSpan={2}>
                    <Typography variant="inherit" gutterBottom>
                      <Chip
                        label={(this.state.active || false)
                          .toString()
                          .toUpperCase()}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Latest Transaction Amount</TableCell>
                  <TableCell align="right" colSpan={2}>
                    <Money balance={this.state.amount} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Latest Transaction Date</TableCell>
                  <TableCell align="right" colSpan={2}>
                    {this.state.date}
                  </TableCell>
                </TableRow>
                <Permissioned permission="stash::bank::account::send_direct_deposit_form">
                  <TableRow>
                    <TableCell>Send Pre-filled DD Form</TableCell>
                    <TableCell>
                      <Typography variant="inherit" gutterBottom>
                        <TextField
                          label="Email"
                          type="email"
                          value={this.state.emailAddress}
                          onChange={this.handleEmailFieldChange.bind(this)}
                          fullWidth
                        />
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.sendPreFilledDDForm.bind(this)}
                      >
                        Send
                      </Button>
                    </TableCell>
                  </TableRow>
                </Permissioned>
              </TableBody>
            </Table>
          </Paper>

        )}
      </div>
    );
  }
}

class DirectDepositStatus extends Component {
  constructor(props) {
    super(props);
    this.userId = props.match.params.userId;
  }

  render() {
    return (
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item={true} xs={12} sm={12} lg={6} xl={6}>
              <Typography variant="subtitle1" gutterBottom>
                Direct Deposit Status
              </Typography>
              <DirectDepositInfo userId={this.userId} />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item={true} xs={12} sm={12} lg={6} xl={6}>
              <Typography variant="subtitle1" gutterBottom>
                Atomic Events Info
              </Typography>
              <AtomicEventInfo userId={this.userId} />
            </Grid>
          </Grid>
        </React.Fragment>
    );
  }
}

export default DirectDepositStatus;

