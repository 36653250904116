import { createStore as reduxCreateStore } from 'redux';
import rootReducer from 'stash/reducers';
import middleware from 'stash/middleware';
import { init } from 'stash/actions/init';

export const createStore = (initialState) => {
  return reduxCreateStore(rootReducer, initialState, middleware);
};

const store = createStore();

store.dispatch(init());

export default store;
