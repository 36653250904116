import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const StatusRow = ({ name, subtexts, children }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="inherit" gutterBottom>
          {name}
        </Typography>
        {subtexts &&
          subtexts.map((text, idx) => (
            <Typography key={idx} variant="inherit" color="primary">
              <small>{text}</small>
            </Typography>
          ))}
      </TableCell>
      <TableCell align="right">{children}</TableCell>
    </TableRow>
  );
};

export default StatusRow;
