import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

class CloseAttempts extends Component {
  constructor(props) {
    super(props);
    this.closeAttempts = props.closeAttempts;
  }

  render() {
    return (
      <div>
        {this.closeAttempts.map((closeAttempt) => {
          return (
            <Typography
              key={closeAttempt.createdAt}
              variant="inherit"
              color="primary"
            >
              <small>
                This account was placed into Spenddown by{' '}
                {closeAttempt.attemptedBy}
              </small>
            </Typography>
          );
        })}
      </div>
    );
  }
}

export { CloseAttempts };
