import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const StatusRow = ({ name, subtexts, children }) => {
  return (
    <TableRow key={name} hover>
      <TableCell>
        <Typography variant="body2" display="block">
          {name}
        </Typography>
        {subtexts &&
          subtexts.map((text, idx) => (
            <Typography key={idx} variant="inherit" color="primary">
              <small>{text}</small>
            </Typography>
          ))}
      </TableCell>
      <TableCell align="right">{children}</TableCell>
    </TableRow>
  );
};

export { StatusRow };
