import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import SidebarGroup from './SidebarGroup';
import { ReferralContext } from 'stash/components/referralProvider';
import {
  getExternalPages,
  InternalNav,
  NavGroup,
  isOnAutoStashPath,
} from 'stash/router/pageGroups';
import { Route } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(3),
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}));

const SideBarItem = (props) => {
  const classes = useStyles();
  const { icon, primary, externalLink, to } = props;
  const Icon = icon;

  const componentProps = {
    href: externalLink ? to : null,
    to: externalLink ? null : to,
  };

  return (
    <ListItem
      button
      component={externalLink ? 'a' : Link}
      {...componentProps}
      className={classes.nested}
    >
      {icon && (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={primary} />
    </ListItem>
  );
};

SideBarItem.propTypes = {
  icon: PropTypes.object,
  primary: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  externalLink: PropTypes.bool.isRequired,
};

const getNavHeadings = (userId) => {
  return isOnAutoStashPath(userId) ? [NavGroup.AUTO_STASH] : [NavGroup.DEBIT];
};

const SidebarItems = () => {
  const { zendeskTicket, zendeskUser, origin } = useContext(ReferralContext);
  const externalPages = getExternalPages({
    zendeskTicket,
    zendeskUser,
    origin,
  });

  return (
    <Route
      path="/users/:userId?"
      render={({ match }) => (
        <List component="nav">
          {getNavHeadings(match.params.userId).map((group) => (
            <SidebarGroup group={group} key={group}>
              {InternalNav.map((pg, idx) => {
                return pg.group === group ? (
                  <SideBarItem
                    externalLink={false}
                    key={`${idx}-internal`}
                    to={pg.getPath(match.params.userId)}
                    primary={pg.title}
                    icon={pg.icon}
                  />
                ) : null;
              })}
            </SidebarGroup>
          ))}
        </List>
      )}
    />
  );
};

export default SidebarItems;
