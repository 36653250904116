import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReferralContext } from 'stash/components/referralProvider';

const params = {
  zendeskTicket: '',
  zendeskUser: '',
  origin: '',
};

export const useReferral = () => {
  const location = useLocation();
  const { setReferral, ...rest } = useContext(ReferralContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(params).forEach((prop) => {
      params[prop] = searchParams.get(prop) || '';
    });

    setReferral(params);

    // eslint-disable-next-line
  }, []);

  return { setReferral, ...rest };
};
